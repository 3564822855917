import moment from "moment";
import { DATE_FORMAT } from "../constants";

export const dateConvert = (value: any) => {
  let date = moment(value).format(DATE_FORMAT.REGULAR);

  return moment(value).format(DATE_FORMAT.REGULAR);
};

export const createdDateConvert = (row: any) =>
  moment(row.original.createdAt).format("D MMM YYYY,H:m:ss a");

export const updatedDateConvert = (row: any) =>
  moment(row.original.updatedAt).format("D MMM YYYY,H:m:ss a");
