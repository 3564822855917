import { emptySplitApi } from "../emptySplit-api";

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: "auth/logout",
        method: "POST",
      }),
    }),
    userInfo: builder.query({
      query: () => `AuthService/user-info`,
    }),
    getOtp: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "auth/forgot-password/otp-request",
          method: "post",
          body: data,
        };
      },
    }),
    validateOtp: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "auth/forgot-password/validate-otp",
          method: "post",
          body: data,
        };
      },
    }),
    resetPassword: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "auth/forgot-password/reset-password",
          method: "post",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useUserInfoQuery,
  useGetOtpMutation,
  useValidateOtpMutation,
  useResetPasswordMutation,
} = authApi;
