import { emptySplitApi } from "../emptySplit-api";

export const vendorApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    vendorList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: "/vendors/list",
          method: "post",
          body: data,
        };
      },
    }),
    vendorGet: builder.query({
      query: (id) => ({ url: `/vendors/get/${id}`, method: "post" }),
    }),
    vendorImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/vendors/import",
          method: "post",
          body: data,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useVendorListQuery,
  useVendorGetQuery,
  useVendorImportMutation,
} = vendorApi;
