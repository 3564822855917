export const icons = {
  ICON_EYE_FILL: "icon-eye-fill",
  ICON_EYE__SLASH_FILL: "icon-eye-slash-fill",
  ICON_ADD: "icon-add",
  ICON_EDIT: "icon-edit",
  ICON_EDIT_1: "icon-edit1",
  ICON_VIEW: "icon-view",
  ICON_FILTER: "icon-filter",
  ICON_DELETE: "icon-delete",
  ICON_CLOSE: "icon-close",
  ICON_PENCIL_CIRCLE: "icon-pencil-circle",
  ICON_CHEVRON_LEFT: "icon-chevron-left",
  ICON_CHEVRON_RIGHT: "icon-chevron-right",
  ICON_CHEVRON_UP: "icon-chevron-up",
  ICON_CHEVRON_DOWN: "icon-chevron-down",
  ICON_NOTIFICATION: "icon-notification",
  ICON_LOGOUT: "icon-logout",
  ICON_INVENTORY: "icon-inventory",
  ICON_KEY: "icon-key",
  ICON_MAIL: "icon-mail",
  ICON_ORDER: "icon-oreders",
  ICON_PRODUCT: "icon-product",
  ICON_REPORTS: "icon-reports",
  ICON_SEARCH: "icon-search",
  ICON_STORE: "icon-store",
  ICON_TRASH: "icon-trash",
  ICON_USERS: "icon-users",
  ICON_BRAND: "icon-brand",
  ICON_CUSTOMERS: "icon-customers",
  ICON_DASHBOARD: "icon-dashboard",
  ICON_PRINTER: "icon-printer",
  ICON_FILE_PDF: "icon-file-pdf",
  ICON_POPULATE: "icon-populate",
  ICON_PRINTER_FILLED: "icon-printer-filled",
  ICON_CARET_DOWN: "icon-caret-down",
  ICON_CARET_UP: "icon-caret-up",
  ICON_CHECK: "icon-check",
  ICON_REFRESH: "icon-refresh",
};
