import { icons } from "./icons";

export const STATUS_CODE = [400, 401, 403, 404];

export const DATE_FORMAT = {
  REGULAR: "D MMM YYYY, H:mm:ss",
  PROJECT_DATE: "D MMM YYYY | H:mm:ss",
  ALBUM_DATE: "MMM Do",
};

export const DATE_NEW_FORMAT = {
  REGULAR: "D MMM YYYY",
  DAY: "dddd",
  TIME: "H:mm:ss",
};

export const DATE_UPDATED_FORMAT = {
  REGULAR: "D MMM, YYYY",
};

export const PAGINATION_DROP = [
  { _id: 10, name: 10 },
  { _id: 50, name: 50 },
  { _id: 100, name: 100 },
  { _id: 200, name: 200 },
];

export const SEARCH_DATA = {
  q: undefined,
  filter: {},
  limit: 10,
  skip: 0,
  sort: "desc",
  sortBy: "createdAt",
  // filterFlag: false,
  // searchFlag: false,
};

export const sidebarData: any = [
  {
    id: "1",
    title: "Dashboard",
    path: "/dashboard",
    icon: icons.ICON_DASHBOARD,
  },
  {
    id: "2",
    title: "Store Management",
    path: "/store-management",
    icon: icons.ICON_STORE,
  },
  // {
  //   id: "3",
  //   title: "Brand Management",
  //   path: "/brand-management",
  // icon: icons.ICON_BRAND,
  // },
  {
    id: "4",
    title: "Product Management",
    path: "/product-management",
    icon: icons.ICON_PRODUCT,
  },
  {
    id: "5",
    title: "Inventory Management",
    path: "/inventory-management",
    icon: icons.ICON_INVENTORY,
  },
  {
    id: "6",
    title: "Vendor Management",
    path: "/vendor-management",
    icon: icons.ICON_INVENTORY,
  },
  {
    id: "7",
    title: "Order Management",
    path: "/order-management",
    icon: icons.ICON_ORDER,
  },
  {
    id: "8",
    title: "Customer Management",
    path: "/customer-management",
    icon: icons.ICON_CUSTOMERS,
  },

  {
    id: "9",
    title: "User Management",
    icon: icons.ICON_USERS,
    subItems: [
      { id: "9-1", title: "Users", path: "/user-management/users" },
      { id: "9-2", title: "Roles", path: "/user-management/roles" },
    ],
  },
  {
    id: "10",
    title: "Store Coordinators",
    icon: icons.ICON_USERS,
    path: "/store-coordinator",
  },
  {
    id: "11",
    title: "Reports",
    path: "/reports",
    icon: icons.ICON_REPORTS,
  },
];

export const sidebarPaths: any = [
  "/dashboard",
  "/product-management",
  "/store-management",
  "/inventory-management",
  "/vendor-management",
  "/user-management",
  "/customer-management",
  "/order-management",
  "/order-details",
  "/store-coordinator",
  "/user-profile",
];

export const PERMISSIONS = [
  // "product.list",
  // "product.create",
  // "product.update",
  // "product.delete",
  "store.edit",
  "store.view",
  "product.edit",
  "product.view",
  "inventory.edit",
  "inventory.view",
  "vendor.edit",
  "vendor.view",
  "order.edit",
  "order.view",
  "customer.edit",
  "customer.view",
  // "store.list",
  // "store.create",
  // "store.update",
  // "store.delete",

  // "vendor.list",
  // "vendor.create",
  // "vendor.update",
  // "vendor.delete",

  // "customer.list",
  // "customer.create",
  // "customer.update",
  // "customer.delete",

  // "order.list",
  // "order.create",
  // "order.update",
  // "order.delete",

  // "inventory.list",
  // "inventory.create",
  // "inventory.update",
  // "inventory.delete",

  // "report.list",
  // "report.create",
  // "report.update",
  // "report.delete",
  "storeCoordinator.edit",
  "storeCoordinator.view",
  "report.edit",
  "report.view",
];

const orderGetData = [
  {
    orderDetails: {
      itemCode: "#12312",
      designNo: "2345",
      itemSize: "M",
      itemColor: "Red",
      orderDate: "25/8/2023",
      destinationSite: "KORA",
      tradeGroup: "#215425",
      deliveryDate: "25/8/2023",
      lotNo: "40",
      Price: "56",
      subAssemblyItem: [
        {
          pant: [
            {
              size: "L",
              value: "31.5",
            },
            {
              size: "SH",
              value: "19.5",
            },
            {
              size: "SL",
              value: "26.5",
            },
            {
              size: "CH",
              value: "48",
            },
            {
              size: "ST",
              value: "47",
            },
            {
              size: "H",
              value: "47.5",
            },
            {
              size: "N",
              value: "16.5",
            },
            {
              size: "C.B",
              value: "18",
            },
            {
              size: "C.F",
              value: "17",
            },
          ],
          dupatta: [
            {
              size: "L",
              value: "31.5",
            },
            {
              size: "SH",
              value: "19.5",
            },
            {
              size: "SL",
              value: "26.5",
            },
            {
              size: "CH",
              value: "48",
            },
            {
              size: "ST",
              value: "47",
            },
            {
              size: "H",
              value: "47.5",
            },
            {
              size: "N",
              value: "16.5",
            },
            {
              size: "C.B",
              value: "18",
            },
            {
              size: "C.F",
              value: "17",
            },
          ],
          kurta: [
            {
              size: "L",
              value: "31.5",
            },
            {
              size: "SH",
              value: "19.5",
            },
            {
              size: "SL",
              value: "26.5",
            },
            {
              size: "CH",
              value: "48",
            },
            {
              size: "ST",
              value: "47",
            },
            {
              size: "H",
              value: "47.5",
            },
            {
              size: "N",
              value: "16.5",
            },
            {
              size: "C.B",
              value: "18",
            },
            {
              size: "C.F",
              value: "17",
            },
          ],
        },
      ],
    },
    customerInformation: {
      name: "",
      phone: "",
      email: "",
      address: "",
    },
    trackingInformation: {
      itemName: "",
      itemStatus: "",
      subAssemblyItem: [
        {
          pant: {
            status: "",
          },
          dupatta: {
            status: "",
          },
          kurta: {
            status: "",
          },
        },
      ],
    },
  },
];
