import { emptySplitApi } from "../emptySplit-api";

export const storeCoordinatorApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    storeCoordinatorList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: "/store-coordinators/list",
          method: "post",
          body: data,
        };
      },
    }),
    storeCoordinatorCreate: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/store-coordinators/create",
          method: "post",
          body: data,
        };
      },
    }),
    storeCoordinatorUpdate: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/store-coordinators/update/${id}`,
          method: "post",
          body: data,
        };
      },
    }),
    storeCoordinatorDelete: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/store-coordinators/delete",
          method: "post",
          body: data,
        };
      },
    }),
    storeCoordinatorGet: builder.query<any, any>({
      query: (id: any) => {
        return {
          url: `/store-coordinators/get/${id}`,
          method: "post",
          // body: id,
        };
      },
    }),
    csvImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/import",
          method: "post",
          body: data,
        };
      },
    }),
    csvExport: builder.mutation<any, any>({
      query: () => {
        return {
          url: "/store-coordinators/export",
          method: "post",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useStoreCoordinatorListQuery,
  useStoreCoordinatorCreateMutation,
  useStoreCoordinatorUpdateMutation,
  useStoreCoordinatorDeleteMutation,
  useStoreCoordinatorGetQuery,
  useCsvExportMutation,
  useCsvImportMutation,
} = storeCoordinatorApi;
