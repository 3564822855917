import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { ACTION_LABEL, BUTTON_LABEL, icons, LABELS } from "../shared/constants";
import TextBox from "../shared/components/TextBox";

const SetPassword = (props: any) => {
  const { resetData, handleOnChange, error, setError, handleSavePassword } =
    props;

  return (
    <>
      <Form>
        <div className="form-group form-group-icon">
          <TextBox
            name={ACTION_LABEL.PASSWORD}
            placeholder={LABELS.PASSWORD_TEXT}
            id={LABELS.PASSWORD_TEXT}
            label={LABELS.PASSWORD_TEXT}
            onChange={handleOnChange}
            value={resetData.password}
            icon={icons.ICON_KEY}
            errorMessage={error.password ? error.password : null}
          />
        </div>
        {/* <Form.Group className="form-group form-group-icon">
          <i className={`icon ${icons.ICON_KEY}`}></i>
          <Form.Label>{LABELS.NEW_PASSWORD_TEXT}</Form.Label>
          <Form.Control
            type="password"
            placeholder="**************"
            inputMode="text"
            id={ACTION_LABEL.PASSWORD}
            name={ACTION_LABEL.PASSWORD}
            autoFocus
            value={resetData.password}
            onChange={handleOnChange}
          />
        </Form.Group> */}
        <div className="text-center mt-5">
          <Link
            to="#"
            className="btn theme-btn text-uppercase"
            onClick={handleSavePassword}
          >
            {BUTTON_LABEL.SUBMIT}
          </Link>
        </div>
      </Form>
    </>
  );
};

export default SetPassword;
