import React from "react";
import Navbar from "../../Navbar/NavbarComponent";
import Sidebar from "../../Navbar/SidebarComponent";
import { LABELS } from "../../../shared/constants";

const Dashboard = () => {
  return (
    <div className="full-height">
      <Navbar title={LABELS.DASHBOARD_TEXT} />
      {/* <Sidebar /> */}
    </div>
  );
};

export default Dashboard;
