import { emptySplitApi } from "../emptySplit-api";

export const reportApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    reportList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: "/reports/list",
          method: "post",
          body: data,
        };
      },
    }),
    reportGet: builder.query({
      query: (id) => ({ url: `/reports/get/${id}`, method: "post" }),
    }),
  }),
  overrideExisting: false,
});

export const { useReportListQuery, useReportGetQuery } = reportApi;
