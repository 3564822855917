import {
  ERROR_MESSAGE,
  toastError,
  toastSuccess,
  TOAST_MESSAGE,
} from "../constants";

export const roleValidation = (props: any): any => {
  let formErrors: any = {};
  let formValid: any = true;

  const { name, code, permissions } = props;
  if (!name) {
    formValid = false;
    formErrors["name"] = ERROR_MESSAGE.NAME_REQUIRED;
  }

  if (!code) {
    formValid = false;
    formErrors["code"] = ERROR_MESSAGE.CODE_REQUIRED;
  }

  if (permissions.length === 0) {
    formValid = false;
    formErrors["permission"] = ERROR_MESSAGE.PERMISSION_REQUIRED;
  }

  if (name && permissions.length === 0) {
    formValid = false;
    formErrors["permission"] = toastError(TOAST_MESSAGE.PERMISSION_REQUIRED);
  }

  return { formValid, formErrors };
};
