import React from "react";
import Navbar from "../../../../Navbar/NavbarComponent/Navbar";
import Sidebar from "../../../../Navbar/SidebarComponent";
import { Logo } from "../../../../../App/assets/img";

const PrintOrder = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-2">
          <img src={Logo} alt="lock" width={180} />
        </div>
        <div className="col-md-10 text-center">
          <h3>NM Fashion Designs Pvt. Ltd</h3>
          <p>
            NM House, Plot No.9(1), Service Road, Cama Industrial Estate, <br />
            Goregaon (East), Mumbai - 400 063 <br />
            PH : 022 - 62775888
          </p>
        </div>
      </div>
      <div className="inner">
        <div className="row mb-3">
          <div className="col-12 col-md-4">
            <div className="d-flex align-items-center">
              <label htmlFor="">Sr. No.</label>
              <input type="text" className="form-control" placeholder="38062" />
            </div>
          </div>
          <div className="col-12 col-md-4 ms-auto">
            <div className="d-flex align-items-center">
              <label htmlFor="">Date :</label>
              <input type="text" className="form-control" placeholder="38062" />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <label htmlFor="">Cus.</label>
              <input type="text" className="form-control" placeholder="38062" />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <label htmlFor="">D. No.</label>
              <input type="text" className="form-control" placeholder="38062" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="item-card">
              <div className="item-header ">
                <span>Jodhpuri</span>
              </div>
              <div className="item-body">
                <div className="populate-values">
                  <div className="value">
                    <label htmlFor="">L</label>
                    <span className="label-box">31.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">SH</label>
                    <span className="label-box">19.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">SL</label>
                    <span className="label-box">26.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">CH</label>
                    <span className="label-box">48</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">ST</label>
                    <span className="label-box">47</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">H</label>
                    <span className="label-box">47.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">N</label>
                    <span className="label-box">16.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">C.B</label>
                    <span className="label-box">18</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">C.F</label>
                    <span className="label-box">17</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">L</label>
                    <span className="label-box">31.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">SH</label>
                    <span className="label-box">19.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">SL</label>
                    <span className="label-box">26.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">CH</label>
                    <span className="label-box">48</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">ST</label>
                    <span className="label-box">47</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">H</label>
                    <span className="label-box">47.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">N</label>
                    <span className="label-box">16.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">C.B</label>
                    <span className="label-box">18</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">C.F</label>
                    <span className="label-box">17</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-card mb-2">
              <div className="item-header ">
                <span>Jodhpuri</span>
              </div>
              <div className="item-body">
                <div className="populate-values">
                  <div className="value">
                    <label htmlFor="">L</label>
                    <span className="label-box">31.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">SH</label>
                    <span className="label-box">19.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">SL</label>
                    <span className="label-box">26.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">CH</label>
                    <span className="label-box">48</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">ST</label>
                    <span className="label-box">47</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">H</label>
                    <span className="label-box">47.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">N</label>
                    <span className="label-box">16.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">C.B</label>
                    <span className="label-box">18</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">C.F</label>
                    <span className="label-box">17</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">L</label>
                    <span className="label-box">31.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">SH</label>
                    <span className="label-box">19.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">SL</label>
                    <span className="label-box">26.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">CH</label>
                    <span className="label-box">48</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">ST</label>
                    <span className="label-box">47</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">H</label>
                    <span className="label-box">47.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">N</label>
                    <span className="label-box">16.5</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">C.B</label>
                    <span className="label-box">18</span>
                  </div>
                  <div className="value">
                    <label htmlFor="">C.F</label>
                    <span className="label-box">17</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="" className="d-inline-block">
            Remark
          </label>
          <textarea
            name=""
            id=""
            className="form-control"
            placeholder="Remark"
          ></textarea>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button className="btn btn theme-btn me-3">
          <span>Print</span>
        </button>
        <button className="btn btn theme-btn btn-inverse">
          <span>Close</span>
        </button>
      </div>
    </>
  );
};

export default PrintOrder;
