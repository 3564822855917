import { icons } from "../../constants";

const Search = (props: any) => {
  const {
    onChange,
    name,
    onClick,
    onKeyDown,
    id,
    className,
    placeholder,
    value,
  } = props;

  return (
    <>
      <div className="col-12 col-md-7 ms-auto">
        <div className="form-group mb-0 search-input position-relative">
          <div className="icon" onClick={onClick}>
            <i className={`icon ${icons.ICON_SEARCH}`}></i>
          </div>
          <input
            type="text"
            className={className ? className : "form-control bg-white"}
            placeholder={placeholder}
            id={id}
            name={name}
            value={value}
            onKeyDown={onKeyDown}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export default Search;
