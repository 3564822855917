import { useEffect, useMemo, useRef, useState } from "react";
import Navbar from "../../Navbar/NavbarComponent";
import CustomModal from "../../../shared/components/CustomModal";
import CustomerDetail from "./CustomerDetail";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  toastError,
  toastSuccess,
  TOAST_MESSAGE,
} from "../../../shared/constants";
import Table from "../../../shared/components/Table";
import Search from "../../../shared/components/Search";
import { listCheck } from "../../../shared/helperFunc/listCheck";
import { useDeleteModal } from "../../../shared/utils/hooks/useDeleteModal";
import {
  useCustomerGetQuery,
  useCustomerImportMutation,
  useCustomerListQuery,
  useCustomerUpdateMutation,
} from "../../../Features/customer/customer-slice";
import { skipToken } from "@reduxjs/toolkit/query";

const Customer = () => {
  const [sort, setSort] = useState(SEARCH_DATA);
  const [search, setSearch] = useState(false);
  const [count, setCount] = useState();
  const [multiDelete, setMultiDelete] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [customerId, setCustomerId]: any = useState();
  const [customerDetailData, setCustomerDetailData]: any = useState();
  const iconInput = useRef<any>(null);

  const [
    showModal,
    deletedName,
    deleteId,
    deleteShowModal,
    deleteCloseModal,
    deleteShowModals,
  ] = useDeleteModal();

  const {
    data,
    isSuccess,
    error,
    refetch: customerRefetch,
  } = useCustomerListQuery(sort);

  const {
    data: getCustomerData,
    isLoading: getCustomerLoading,
    isSuccess: getCustomerSuccess,
  } = useCustomerGetQuery(customerId ? customerId : skipToken);

  const [customerUpdate, { isSuccess: customerUpdateSuccess }] =
    useCustomerUpdateMutation();

  const [
    customerImport,
    { isSuccess: customerImportSuccess, isLoading: customerImportLoading },
  ] = useCustomerImportMutation();

  useEffect(() => {
    if (customerId !== undefined && getCustomerSuccess) {
      setCustomerDetailData(getCustomerData.item);
    }
  }, [customerId, getCustomerData, getCustomerSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setCustomerList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  const customerImportFlow = (data: any) => {
    customerRefetch();
    toastSuccess(data.message);
  };

  const handleClose = () => {
    setShowDetail(false);
  };

  const handleSearch = () => {
    // if (val === "") {
    //   setSearch(!search);
    //   setSort({ ...sort, q: undefined, skip: 0 });
    // } else {
    //   setSearch(!search);
    //   setSort({ ...sort, skip: 0 });
    // }

    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value });
    // if (e.target.value.length === 0) {
    //   handleSearch(e.target.value);
    // }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
      // handleSearch(e.target.value);
    }
  };

  // const customerUpdateFlow = async (data: any) => {
  //   toastSuccess(data.message);
  //   customerRefetch();
  // };

  // const handleSwitchChange = async (id: any, isActive: any) => {
  //   const customerUpdateResponse = await customerUpdate({
  //     id: id,
  //     data: { isActive: !isActive },
  //   })
  //     .unwrap()
  //     .catch((err: any) => {
  //       toastError(err.data?.errors[0]?.msg);
  //     });
  //   if (customerUpdateResponse && customerUpdateResponse?.status === true) {
  //     customerUpdateFlow(customerUpdateResponse);
  //   }
  // };

  const customerModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: handleClose,
      className: "btn btn theme-btn",
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: LABELS.SALUTATION_TEXT,
        accessorKey: ACTION_LABEL.SALUTATION,
        isShow: true,
      },
      {
        header: LABELS.FIRSTNAME_TEXT,
        accessorKey: ACTION_LABEL.FIRSTNAME,
        isShow: true,
      },
      {
        header: LABELS.MIDDLE_NAME_TEXT,
        accessorKey: ACTION_LABEL.MIDDLENAME,
        isShow: true,
      },
      {
        header: LABELS.LASTNAME_TEXT,
        accessorKey: ACTION_LABEL.LASTNAME,
        isShow: true,
      },
      {
        header: `${LABELS.ADDRESS_TEXT} 1`,
        accessorKey: `${ACTION_LABEL.ADDRESS}1`,
        isShow: true,
      },
      {
        header: `${LABELS.ADDRESS_TEXT} 2`,
        accessorKey: `${ACTION_LABEL.ADDRESS}2`,
        isShow: false,
      },
      {
        header: LABELS.CITY_TEXT,
        accessorKey: ACTION_LABEL.CITY,
        isShow: true,
      },
      {
        header: LABELS.STATE_TEXT,
        accessorKey: ACTION_LABEL.STATE,
        isShow: true,
      },
      {
        header: LABELS.DISTRICT_TEXT,
        accessorKey: ACTION_LABEL.DISTRICT,
        isShow: false,
      },
      {
        header: LABELS.PIN_TEXT,
        accessorKey: ACTION_LABEL.PIN,
        isShow: false,
      },
      {
        header: LABELS.EMAIL_TEXT,
        accessorKey: ACTION_LABEL.EMAIL,
        isShow: false,
      },
      {
        header: LABELS.MOBILE_NO_TEXT,
        accessorKey: ACTION_LABEL.MOBILE_NO,
        isShow: false,
      },
      {
        header: LABELS.REMARKS_TEXT,
        accessorKey: ACTION_LABEL.REMARKS,
        isShow: false,
      },
      {
        header: LABELS.GST_STATE_TEXT,
        accessorKey: ACTION_LABEL.GST_STATE_NAME,
        isShow: false,
      },
      {
        header: LABELS.GST_IN_TEXT,
        accessorKey: ACTION_LABEL.GST_IN,
        isShow: false,
      },
    ],
    [sort]
  );

  const setCheckBox = (flag: any) => {
    let updated = listCheck(JSON.parse(JSON.stringify(customerList)), flag);
    return updated;
  };

  const checkSelectedData = (data: any) => {
    return data.filter((ele: any) => {
      return ele.isSelected;
    });
  };

  const checked = (e: any, index: any) => {
    setMultiDelete(true);
    let data = JSON.parse(JSON.stringify(customerList));

    if (e.target.checked) {
      data[index].isSelected = true;
    } else {
      data[index].isSelected = false;
    }
    setCustomerList(data);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);
  };

  const removeMultiDelete = () => {
    let unSelected = setCheckBox(false);
    setCustomerList(unSelected);
    const visibleName = checkSelectedData(unSelected);
    deleteShowModal(visibleName);
  };

  const actionMultiDelete = () => {
    deleteShowModals();
    const visibleName = checkSelectedData(customerList);
    deleteShowModal(visibleName);
  };

  const allCheck = (e: any) => {
    setMultiDelete(true);
    let data = [];
    data = setCheckBox(e.target.checked);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);

    setCustomerList(data);
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const customerImportResponse = await customerImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (customerImportResponse && customerImportResponse?.status === true) {
      customerImportFlow(customerImportResponse);
    }
  };

  const handleView = (row: any) => {
    setCustomerId(row._id);
    setShowDetail(true);
  };

  const handleEdit = (row: any) => {};
  const handleShowModal = (row: any) => {};

  const tableAction = (
    handleView: any,
    handleEdit: any,
    handleShowModal: any
  ) => {
    return [
      {
        elementType: "button",
        text: BUTTON_LABEL.VIEW,
        className: `${`icon ${icons.ICON_VIEW}`} mx-1 `,
        icon: `icon ${icons.ICON_VIEW}`,
        action: handleView,
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.EDIT,
        className: `${`icon ${icons.ICON_EDIT}`} mx-1 `,
        // icon: "",
        action: handleEdit,
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.DELETE,
        className: `${`icon ${icons.ICON_DELETE}`} mx-1 `,
        // icon: "",
        action: handleShowModal,
      },
    ];
  };

  const action = tableAction(handleView, handleEdit, handleShowModal);

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.CUSTOMER_MANAGEMENT_TEXT} />
        {/* <Sidebar /> */}
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <a
                    href="#"
                    className="me-2 btn theme-btn theme-btn-sm btn-inverse"
                  >
                    <i
                      className={`icon ${icons.ICON_FILTER} me-2 d-inline-block`}
                    ></i>
                    {BUTTON_LABEL.FILTER}
                  </a>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />
                    <a
                      href="#"
                      className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      onClick={handleRef}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={iconInput}
                        onChange={handleCsvImport}
                        accept=".csv"
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                        hidden
                      />
                      {BUTTON_LABEL.IMPORT}
                    </a>

                    <a
                      href="#"
                      className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      // onClick={handleCsvExport}
                    >
                      {BUTTON_LABEL.EXPORT}
                    </a>
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={customerList}
                    columns={columns}
                    select={true}
                    action={action}
                    sort={sort}
                    setSort={setSort}
                    allCheck={allCheck}
                    allCheckedCheckbox={deleteId.length === customerList.length}
                    checked={checked}
                    coloumnDrop={true}
                    count={count}
                    deleteId={deleteId}
                    removeMultiDelete={removeMultiDelete}
                    actionMultiDelete={actionMultiDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <CustomModal
        show={showDetail}
        close={handleClose}
        size={"lg"}
        message={""}
        modalTitle={LABELS.CUSTOMER_DETAILS_TEXT}
        modalButton={customerModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <CustomerDetail
          customerDetailData={customerDetailData}
          getCustomerLoading={getCustomerLoading}
        />
      </CustomModal>
      ;
    </>
  );

  // return (
  //   <>
  //     <div className="full-height">
  //       <Navbar title={LABELS.CUSTOMER_MANAGEMENT_TEXT} />
  //       <main className="main-content">
  //         <div className="pt-3">
  //           <div className="container-fluid">
  //             <div className="row table-top-actions">
  //               <div className="col-12 col-md-4 d-flex align-items-center">
  //                 <a
  //                   href="#"
  //                   className="me-2 btn theme-btn theme-btn-sm btn-inverse"
  //                 >
  //                   <i
  //                     className={`icon ${icons.ICON_FILTER} me-2 d-inline-block`}
  //                   ></i>
  //                   Filter
  //                 </a>
  //               </div>
  //               <div className="col-12 col-md-8 d-flex justify-content-end">
  //                 <div className="d-flex align-items-center row w-100 mx-m-0">
  //                   <div className="col-12 col-md-7 ms-auto">
  //                     <div className="form-group mb-0 search-input position-relative ">
  //                       <div className="icon">
  //                         <i className={`icon ${icons.ICON_SEARCH} `}></i>
  //                       </div>
  //                       <input
  //                         type="text"
  //                         className="form-control bg-white"
  //                         placeholder="Search"
  //                       />
  //                     </div>
  //                   </div>
  //                   <a
  //                     href="#"
  //                     onClick={handleShow}
  //                     className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
  //                   >
  //                     Export
  //                   </a>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="card custom-card card-full mt-3">
  //               <div className="card-body p-0">
  //                 <div className="row">
  //                   <div className="col-12">
  //                     <div className="table-responsive">
  //                       {/* d-none */}
  //                       <div className="sticky-action">
  //                         <span>10 items selected</span>
  //                         <i className={`icon ${icons.ICON_CLOSE}`}></i>
  //                         <i className={`icon ${icons.ICON_DELETE}`}></i>
  //                       </div>
  //                       <table className="table table-header-shadow cell-1-small mb-0">
  //                         <thead>
  //                           <tr>
  //                             <th>
  //                               <div className="custom-checkbox">
  //                                 <input
  //                                   className="custom-control-input"
  //                                   type="checkbox"
  //                                   id="inlineCheckbox1"
  //                                   value="option1"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="inlineCheckbox1"
  //                                 ></label>
  //                               </div>
  //                             </th>
  //                             <th>Salutation</th>
  //                             <th>First Name</th>
  //                             <th>Middle Name</th>
  //                             <th>Last Name</th>
  //                             <th>Address</th>
  //                             <th>City</th>
  //                             <th>State</th>
  //                             <th>
  //                               <div className="dropdown cell-dropdown"></div>
  //                               <Dropdown className="dropdown cell-dropdown float-end">
  //                                 <Dropdown.Toggle className="no-btn">
  //                                   <a href="#">
  //                                     <svg
  //                                       xmlns="http://www.w3.org/2000/svg"
  //                                       width="18"
  //                                       height="18.015"
  //                                       viewBox="0 0 18 18.015"
  //                                     >
  //                                       <g
  //                                         id="Group_11176"
  //                                         data-name="Group 11176"
  //                                         transform="translate(-1297.437 -289.429)"
  //                                       >
  //                                         <g
  //                                           id="Rectangle_19126"
  //                                           data-name="Rectangle 19126"
  //                                           transform="translate(1297.437 289.437)"
  //                                           fill="#fff"
  //                                           stroke="#707070"
  //                                           stroke-width="1"
  //                                         >
  //                                           <rect
  //                                             width="18"
  //                                             height="18"
  //                                             stroke="none"
  //                                           ></rect>
  //                                           <rect
  //                                             x="0.5"
  //                                             y="0.5"
  //                                             width="17"
  //                                             height="17"
  //                                             fill="none"
  //                                           ></rect>
  //                                         </g>
  //                                         <line
  //                                           id="Line_75"
  //                                           data-name="Line 75"
  //                                           y2="18.015"
  //                                           transform="translate(1303.434 289.429)"
  //                                           fill="none"
  //                                           stroke="#707070"
  //                                           stroke-width="1"
  //                                         ></line>
  //                                         <line
  //                                           id="Line_76"
  //                                           data-name="Line 76"
  //                                           y2="18.015"
  //                                           transform="translate(1309.439 289.429)"
  //                                           fill="none"
  //                                           stroke="#707070"
  //                                           stroke-width="1"
  //                                         ></line>
  //                                       </g>
  //                                     </svg>
  //                                   </a>
  //                                 </Dropdown.Toggle>
  //                                 <Dropdown.Menu className="dropdown-menu has-checkbox">
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat1"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat1"
  //                                         >
  //                                           Category 1
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat2"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat2"
  //                                         >
  //                                           Category 2
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat3"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat3"
  //                                         >
  //                                           Category 3
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat4"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat4"
  //                                         >
  //                                           Category 4
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat5"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat5"
  //                                         >
  //                                           Category 5
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat6"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat6"
  //                                         >
  //                                           Category 6
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                 </Dropdown.Menu>
  //                               </Dropdown>
  //                             </th>
  //                           </tr>
  //                         </thead>
  //                         <tbody>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>
  //                               <div className="custom-control custom-checkbox d-inline-block">
  //                                 <input
  //                                   type="checkbox"
  //                                   className="custom-control-input"
  //                                   id="check_2"
  //                                 />
  //                                 <label
  //                                   className="custom-control-label"
  //                                   htmlFor="check_2"
  //                                 ></label>
  //                               </div>
  //                             </td>
  //                             <td>Mr.</td>
  //                             <td>John</td>
  //                             <td>Cris </td>
  //                             <td>Doe</td>
  //                             <td>San house rydot infotech</td>
  //                             <td>Ahmedabad</td>
  //                             <td>Gujarat</td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                         </tbody>
  //                       </table>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="row align-items-center pagination">
  //                   <div className="col-12 col-md-4">
  //                     <div className="form-group mb-0">
  //                       Show
  //                       <select
  //                         name=""
  //                         id=""
  //                         className="form-control w-auto d-inline-block"
  //                       >
  //                         <option value="10">10</option>
  //                         <option value="50">50</option>
  //                         <option value="100">100</option>
  //                       </select>
  //                       &nbsp; Entries
  //                     </div>
  //                   </div>
  //                   <div className="col-12 col-md-4">
  //                     <div className="form-group mb-0 text-center">
  //                       Showing 1 to 10 of 223 entries
  //                     </div>
  //                   </div>
  //                   <div className="col-12 col-md-4">
  //                     <nav>
  //                       <ul className="pagination mb-0 justify-content-end">
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             &lt;
  //                           </a>
  //                         </li>
  //                         <li className="page-item active">
  //                           <a className="page-link" href="#">
  //                             1
  //                           </a>
  //                         </li>
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             2
  //                           </a>
  //                         </li>
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             3
  //                           </a>
  //                         </li>
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             &gt;
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </nav>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </main>
  //     </div>

  //   </>
  // );
};

export default Customer;
