import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const inventoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    inventoryList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/list`,
          method: "post",
          body: data,
        };
      },
    }),
    inventoryGet: builder.query({
      query: (id) => ({
        url: `/${API_URL.INVENTORIES}/get/${id}`,
        method: "post",
      }),
    }),
    inventoryImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.INVENTORIES}/import`,
          method: "post",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useInventoryListQuery,
  useInventoryGetQuery,
  useInventoryImportMutation,
} = inventoryApi;
