import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import ButtonLoader from "../ButtonLoader";
import SimpleButton from "../Button/Button";
import { icons } from "../../constants";

interface CustomModalProps {
  show: boolean;
  close: () => void | any;
  size: string | any;
  message: string;
  children: React.ReactNode;
  modalTitle: string;
  modalButton:
    | {
        isLoading: boolean;
        text: string | React.ReactNode | any;
        action: () => void | any;
        className: string;
      }[]
    | any;
  fullscreen: boolean | any;
  className: string;
  centerClass: string;
  modalTitleIcon: string;
}

const CustomModal: FC<CustomModalProps> = (props) => {
  const {
    show,
    close,
    size,
    message,
    children,
    modalTitle,
    modalButton,
    fullscreen,
    className,
    centerClass,
    modalTitleIcon,
  } = props;

  const displayButton = (
    btn: CustomModalProps["modalButton"][0],
    index: number
  ) => {
    return (
      Object.keys(btn).length > 0 && (
        <SimpleButton
          key={index}
          text={btn.isLoading ? <ButtonLoader /> : btn.text}
          onClick={btn.action}
          className={btn.className}
        />
      )
    );
  };

  return (
    <Modal
      show={show}
      onHide={close}
      scrollable={true}
      fullscreen={fullscreen ? true : undefined}
      size={size}
      className={`modal-dialog-centered mobile-top-m-fix ${
        centerClass ? centerClass : ""
      }`}
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {modalTitleIcon && <i className={`${modalTitleIcon} mr-3`} />}
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={className ? className : ""}>
        <div className="t-select-model">
          <p className="word-break mb-0 text-align-center">{message}</p>
          {children}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {modalButton &&
          modalButton.map((btn: any, index: any) => {
            return displayButton(btn, index);
          })}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
