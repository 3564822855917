import React from "react";
import TextBox from "../../../../shared/components/TextBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";

const InventoryDetail = (props: any) => {
  const { inventoryDetailData, getInventoryLoading } = props;
  return (
    <>
      {!getInventoryLoading && inventoryDetailData !== undefined ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CODE_TEXT}
                  placeholder={LABELS.CODE_TEXT}
                  name={ACTION_LABEL.CODE}
                  value={inventoryDetailData.code}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.CATEGORY_TEXT} 6`}
                  placeholder={`${LABELS.CATEGORY_TEXT} 6`}
                  name={`${ACTION_LABEL.CATEGORY}6`}
                  value={inventoryDetailData.category6}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.STRING_DESC_TEXT}5`}
                  placeholder={`${LABELS.STRING_DESC_TEXT}5`}
                  name={`${ACTION_LABEL.CATEGORY}5`}
                  value={inventoryDetailData.stringDesc5}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.DIVISION_TEXT}
                  placeholder={LABELS.DIVISION_TEXT}
                  name={ACTION_LABEL.DIVISION}
                  value={inventoryDetailData.division}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.HSN_CODE_TEXT}
                  placeholder={LABELS.HSN_CODE_TEXT}
                  name={ACTION_LABEL.HSN_CODE}
                  value={inventoryDetailData.hsnCode}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.STRING_DESC_TEXT}6`}
                  placeholder={`${LABELS.STRING_DESC_TEXT}6`}
                  name={`${ACTION_LABEL.STRING_DESC}6`}
                  value={inventoryDetailData.stringDesc6}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.SECTION_TEXT}
                  placeholder={LABELS.SECTION_TEXT}
                  name={ACTION_LABEL.SECTION}
                  value={inventoryDetailData.section}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.MATERIAL_TYPE_TEXT}
                  placeholder={LABELS.MATERIAL_TYPE_TEXT}
                  name={ACTION_LABEL.SECTION}
                  value={inventoryDetailData.materialType}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.UOM_TEXT}
                  placeholder={LABELS.UOM_TEXT}
                  name={ACTION_LABEL.USERNAME}
                  value={inventoryDetailData.uom}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.DEPARTMENT_TEXT}
                  placeholder={LABELS.DEPARTMENT_TEXT}
                  name={ACTION_LABEL.DEPARTMENT}
                  value={inventoryDetailData.department}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.MRP_TEXT}
                  placeholder={LABELS.MRP_TEXT}
                  name={ACTION_LABEL.MRP}
                  value={inventoryDetailData.mrp}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.WSP_TEXT}
                  placeholder={LABELS.WSP_TEXT}
                  name={ACTION_LABEL.WSP}
                  value={inventoryDetailData.wsp}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.CATEGORY_TEXT} 1`}
                  placeholder={`${LABELS.CATEGORY_TEXT} 1`}
                  name={`${ACTION_LABEL.CATEGORY}1`}
                  value={inventoryDetailData.category1}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.RSP_TEXT}
                  placeholder={LABELS.RSP_TEXT}
                  name={ACTION_LABEL.RSP}
                  value={inventoryDetailData.rsp}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.LAST_INWARD_RATE_TEXT}
                  placeholder={LABELS.LAST_INWARD_RATE_TEXT}
                  name={ACTION_LABEL.LAST_INWARD_RATE}
                  value={inventoryDetailData.lastInwardRate}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.CATEGORY_TEXT} 2`}
                  placeholder={`${LABELS.CATEGORY_TEXT} 2`}
                  name={`${ACTION_LABEL.CATEGORY}2`}
                  value={inventoryDetailData.category2}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.STRING_DESC_TEXT}1`}
                  placeholder={`${LABELS.STRING_DESC_TEXT}1`}
                  name={`${ACTION_LABEL.STRING_DESC}1`}
                  value={inventoryDetailData.stringDesc1}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.STANDARD_RATE_TEXT}
                  placeholder={LABELS.STANDARD_RATE_TEXT}
                  name={`${ACTION_LABEL.STANDARD_RATE}`}
                  value={inventoryDetailData.standardRate}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.CATEGORY_TEXT} 3`}
                  placeholder={`${LABELS.CATEGORY_TEXT} 3`}
                  name={`${ACTION_LABEL.CATEGORY}3`}
                  value={inventoryDetailData.category3}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.STRING_DESC_TEXT}2`}
                  placeholder={`${LABELS.STRING_DESC_TEXT}2`}
                  name={`${ACTION_LABEL.STRING_DESC}2`}
                  value={inventoryDetailData.stringDesc2}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.LAST_STOCK_INWARD_DATE_TEXT}
                  placeholder={LABELS.LAST_STOCK_INWARD_DATE_TEXT}
                  name={`${ACTION_LABEL.LAST_STOCK_INWARD_RATE}`}
                  value={inventoryDetailData.lastStockInwardDate}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.CATEGORY_TEXT} 4`}
                  placeholder={`${LABELS.CATEGORY_TEXT} 4`}
                  name={`${ACTION_LABEL.CATEGORY}4`}
                  value={inventoryDetailData.category4}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.STRING_DESC_TEXT}3`}
                  placeholder={`${LABELS.STRING_DESC_TEXT}3`}
                  name={`${ACTION_LABEL.STRING_DESC}3`}
                  value={inventoryDetailData.stringDesc3}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.BARCODE_TEXT}
                  placeholder={LABELS.BARCODE_TEXT}
                  name={ACTION_LABEL.BARCODE}
                  value={inventoryDetailData.barcode}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.CATEGORY_TEXT} 5`}
                  placeholder={`${LABELS.CATEGORY_TEXT} 5`}
                  name={`${ACTION_LABEL.CATEGORY}5`}
                  value={inventoryDetailData.category5}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={`${LABELS.STRING_DESC_TEXT}4`}
                  placeholder={`${LABELS.STRING_DESC_TEXT}4`}
                  name={`${ACTION_LABEL.STRING_DESC}4`}
                  value={inventoryDetailData.stringDesc4}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.STOCK_POINT_TEXT}
                  placeholder={LABELS.STOCK_POINT_TEXT}
                  name={ACTION_LABEL.STOCK_POINT}
                  value={inventoryDetailData.stockPoint}
                  disable
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CREATED_ON_TEXT}
                  placeholder={LABELS.CREATED_ON_TEXT}
                  name={ACTION_LABEL.CREATED_ON}
                  value={inventoryDetailData.createdOn}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.LAST_MODIFIED_ON_TEXT}
                  placeholder={LABELS.LAST_MODIFIED_ON_TEXT}
                  name={ACTION_LABEL.LAST_MODIFIED_ON}
                  value={inventoryDetailData.lastModifiedOn}
                  disable
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CLOSING_QTY_TEXT}
                  placeholder={LABELS.CLOSING_QTY_TEXT}
                  name={ACTION_LABEL.CLOSING_QTY}
                  value={inventoryDetailData.closingQTY}
                  disable
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default InventoryDetail;
