import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Logo } from "../App/assets/img";
import { loginValidation } from "../shared/validation/login-validation";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  INPUT_TYPES,
  LABELS,
  MESSAGE,
  TOAST_MESSAGE,
  toastError,
  toastSuccess,
} from "../shared/constants";
import { useLoginMutation } from "../Features/auth/auth-api-slice";
import { AuthContext } from "../context/AuthContext";
import TextBox from "../shared/components/TextBox";
import PasswordBox from "../shared/components/Password/PasswordBox";
import { log } from "console";

const Login = () => {
  const [login, { isSuccess }] = useLoginMutation();
  const authContext = useContext(AuthContext);

  const [loginDetail, setLoginDetail] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState<any>({});

  const handleChange = (e: any) => {
    setLoginDetail({
      ...loginDetail,
      [e.target.name]: e.target.value,
    });
    setError({ ...error, [e.target.name]: "" });
  };

  const loginFlow = async (loginData: any) => {
    await authContext.setAuthState({
      userInfo: loginData,
      // isAuthenticated: true,
    });
    localStorage.setItem("userInfo", JSON.stringify(loginData));
    localStorage.setItem("x-access-token", loginData.token);
    toastSuccess(loginData.message);
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    const { formErrors, formValid } = loginValidation(loginDetail);

    if (formValid) {
      const LoginResponse = await login({
        username: loginDetail.username,
        password: loginDetail.password,
      })
        .unwrap()
        .catch((err: any) => {
          toastError(err?.data?.errors[0]?.msg);
        });
      if (LoginResponse && LoginResponse?.status === true) {
        loginFlow(LoginResponse);
      }
    } else {
      setError(formErrors);
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="row align-middle">
          <div className="col-md-4 mx-auto">
            <div className="brand-logo text-center mb-3">
              <img src={Logo} alt="lock" width={350} className="img-fluid" />
            </div>
            <h2 className="h2 text-center mb-4">{MESSAGE.TITLE_TEXT}</h2>
            <form>
              {/* <Form.Group className="form-group form-group-icon">
                <i className={`icon ${icons.ICON_MAIL}`}></i>
                <Form.Label>{LABELS.EMAIL_ADDRESS_TEXT}</Form.Label>
                <Form.Control
                  type={INPUT_TYPES.USERNAME}
                  placeholder={LABELS.USERNAME_TEXT}
                  inputMode="text"
                  id={ACTION_LABEL.USERNAME}
                  name={ACTION_LABEL.USERNAME}
                  autoFocus
                  value={loginDetail.username}
                  onChange={handleChange}
                />
                <span className="text-error">
                  {error?.username ? error.username : null}
                </span>
              </Form.Group> */}

              <div className="form-group form-group-icon">
                <TextBox
                  name={ACTION_LABEL.USERNAME}
                  placeholder={LABELS.USERNAME_TEXT}
                  id={LABELS.USERNAME_TEXT}
                  label={LABELS.USERNAME_TEXT}
                  onChange={handleChange}
                  value={loginDetail.username}
                  icon={icons.ICON_MAIL}
                  errorMessage={error.username ? error.username : null}
                />
              </div>
              <div className="form-group form-group-icon">
                <PasswordBox
                  name={ACTION_LABEL.PASSWORD}
                  placeholder={LABELS.PASSWORD_TEXT}
                  id={LABELS.PASSWORD_TEXT}
                  label={LABELS.PASSWORD_TEXT}
                  onChange={handleChange}
                  value={loginDetail.password}
                  icon={icons.ICON_KEY}
                  errorMessage={error.password ? error.password : null}
                />
              </div>
              {/* <Form.Group className="form-group form-group-icon">
                <i className={`icon ${icons.ICON_KEY}`}></i>
                <Form.Label>{LABELS.PASSWORD_TEXT}</Form.Label>
                <Form.Control
                  type={INPUT_TYPES.PASSWORD}
                  placeholder={LABELS.PASSWORD_TEXT}
                  id={ACTION_LABEL.PASSWORD}
                  name={ACTION_LABEL.PASSWORD}
                  value={loginDetail.password}
                  onChange={handleChange}
                />
                <span className="text-error">
                  {error?.password ? error.password : null}
                </span>
              </Form.Group> */}
              <div className="form-group my-3">
                <Link to="/resetpassword">{LABELS.FORGOT_PASSWORD_TEXT}</Link>
              </div>
              <div className="custom-checkbox">
                <input
                  className="custom-control-input"
                  type={INPUT_TYPES.CHECKBOX}
                  id="inlineCheckbox1"
                  value="option1"
                />
                <label
                  className="custom-control-label"
                  htmlFor="inlineCheckbox1"
                >
                  {LABELS.REMEMBER_ME_TEXT}
                  {/* I have read the Privacy Policy and agree to the Terms of
                  Service. */}
                </label>
              </div>
              <div className="text-center mt-3">
                <Button
                  variant="default"
                  className="btn theme-btn text-uppercase"
                  onClick={(e: any) => handleLogin(e)}
                  type="submit"
                >
                  {BUTTON_LABEL.LOGIN}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
