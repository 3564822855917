import { emptySplitApi } from "../emptySplit-api";

export const orderApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    orderList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: "/orders/list",
          method: "post",
          body: data,
        };
      },
    }),
    orderGet: builder.query({
      query: (id) => ({ url: `/orders/get/${id}`, method: "post" }),
    }),
  }),
  overrideExisting: false,
});

export const { useOrderListQuery, useOrderGetQuery } = orderApi;
