import { Children } from "react";

const SimpleButton = (props: any) => {
  const {
    text,
    onClick,
    className,
    disabled,
    btnIcon,
    btnIconClass,
    children,
  } = props;

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled ? true : false}
    >
      {btnIcon ? (
        <i className={`${btnIcon} ${btnIconClass ? btnIconClass : ""}`}></i>
      ) : (
        ""
      )}
      {text && <span>{text}</span>}
      {children}
    </button>
  );
};

export default SimpleButton;
