import React, { useState } from "react";
import { Logo } from "../App/assets/img";
import ResetPassword from "./ResetPassword";
import SetOtp from "./SetOtp";
import SetPassword from "./SetPassword";
import {
  useGetOtpMutation,
  useResetPasswordMutation,
  useValidateOtpMutation,
} from "../Features/auth/auth-api-slice";
import {
  LABELS,
  TOAST_MESSAGE,
  toastError,
  toastSuccess,
} from "../shared/constants";
import { Link, useNavigate } from "react-router-dom";
import {
  otpValidation,
  userNameValidation,
  savePasswordValidation,
} from "../shared/validation/reset-password-validation";

const ForgotPassword = (props: any) => {
  const [step, setStep] = useState(1);
  const [error, setError] = useState({});
  const [resetData, setResetData] = useState<any>({
    username: "",
    password: undefined,
    otp: undefined,
  });
  let passwordContent: any = "";
  const navigate = useNavigate();

  const [getOtp, { isSuccess: getOtpSuccess }] = useGetOtpMutation();

  const [validateOtp, { isSuccess: setOtpSuccess }] = useValidateOtpMutation();

  const [resetPassword, { isSuccess: resetPasswordSuccess }] =
    useResetPasswordMutation();

  const handleOnChange = (e: any) => {
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value,
    });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleOtpInput = (value: any) => {
    setResetData({ ...resetData, otp: value });
    setError({ ...error, otp: "" });
  };

  const nextStep = () => {
    setStep((step) => step + 1);
  };

  const getOtpFlow = (data: any) => {
    // setResetData({ ...resetData, otp: data.otp });
    setStep(step + 1);
  };

  const validateOtpFlow = (data: any) => {
    toastSuccess(data.message);
    setStep(step + 1);
  };

  const resetPasswordFlow = (data: any) => {
    toastSuccess(data.message);
    navigate("/login");
  };

  const handleGetOtp = async (e: any) => {
    e.preventDefault();
    const { formError, formValid } = userNameValidation(resetData.username);

    if (formValid) {
      const getOptResponse = await getOtp(resetData)
        .unwrap()
        .catch((err: any) => {
          toastError(err.data?.errors[0]?.msg);
        });
      if (getOptResponse && getOptResponse?.status === true) {
        getOtpFlow(getOptResponse);
      }
    } else {
      setError(formError);
    }
  };

  const handleValidateOtp = async (e: any) => {
    e.preventDefault();
    const { formValid, formError } = otpValidation(resetData.code);

    if (formValid) {
      const validateOtpResponse = await validateOtp(resetData)
        .unwrap()
        .catch((err: any) => {
          toastError(err.data?.errors[0]?.msg);
        });
      if (validateOtpResponse && validateOtpResponse.status === true) {
        validateOtpFlow(validateOtpResponse);
      }
    } else {
      setError(formError);
    }
  };

  const handleSavePassword = async (e: any) => {
    e.preventDefault();
    const { formValid, formError } = savePasswordValidation(resetData);

    if (formValid) {
      const resetPasswordResponse = await resetPassword(resetData)
        .unwrap()
        .catch((err: any) => {
          toastError(err.data?.errors[0]?.msg);
        });
      if (resetPasswordResponse && resetPasswordResponse?.status === true) {
        resetPasswordFlow(resetPasswordResponse);
      } else {
        setError(formError);
      }
    }
  };

  switch (step) {
    case 1:
      passwordContent = (
        <ResetPassword
          resetData={resetData}
          handleOnChange={handleOnChange}
          handleGetOtp={handleGetOtp}
          handleNextStep={nextStep}
          error={error}
          setError={setError}
        />
      );
      break;

    case 2:
      passwordContent = (
        <SetOtp
          resetData={resetData}
          handleOnChange={handleOtpInput}
          error={error}
          handleValidateOtp={handleValidateOtp}
          setError={setError}
        />
      );
      break;

    case 3:
      passwordContent = (
        <SetPassword
          resetData={resetData}
          handleOnChange={handleOnChange}
          error={error}
          setError={setError}
          handleSavePassword={handleSavePassword}
        />
      );
  }

  return (
    <div className="login-wrapper">
      <div className="row align-middle">
        <div className="col-md-4 mx-auto">
          <div className="brand-logo text-center mb-4">
            <img src={Logo} alt="lock" width={350} className="img-fluid" />
          </div>
          <h2 className="h2 text-center mb-4">{LABELS.FORGOT_PASSWORD_TEXT}</h2>
          {passwordContent}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
