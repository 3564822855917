import { emptySplitApi } from "../emptySplit-api";

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    rolesList: builder.query<any, any>({
      query: (data: any) => ({
        // return {
        url: "/roles/list",
        method: "post",
        body: data,
        // };
      }),
    }),
    rolesCreate: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/roles/create",
          method: "post",
          body: data,
        };
      },
    }),
    rolesUpdate: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/roles/update/${id}`,
          method: "post",
          body: data,
        };
      },
    }),
    rolesDelete: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/roles/delete",
          method: "post",
          body: data,
        };
      },
    }),
    rolesGet: builder.query<any, any>({
      query: (id: any) => {
        return {
          url: `/roles/get/${id}`,
          method: "post",
          // body: id,
        };
      },
    }),
    rolesSearch: builder.query<any, any>({
      query: () => {
        return {
          url: "/roles/search",
          method: "post",
          //   body: data,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useRolesListQuery,
  useRolesCreateMutation,
  useRolesUpdateMutation,
  useRolesDeleteMutation,
  useRolesGetQuery,
  useRolesSearchQuery,
} = userApi;
