import { emptySplitApi } from "../emptySplit-api";

export const userProfileApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    userProfileUpdate: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "account/profile/update",
          method: "post",
          body: data,
        };
      },
    }),
    logoUpload: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/common/upload-image",
          method: "post",
          body: data,
        };
      },
    }),
    userProfileGet: builder.query<any, any>({
      query: (id: any) => {
        return {
          url: `account/profile`,
          method: "post",
          // body: id,
        };
      },
    }),
    changePassword: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "account/change-password",
          method: "post",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useUserProfileUpdateMutation,
  useUserProfileGetQuery,
  useLogoUploadMutation,
  useChangePasswordMutation,
} = userProfileApi;
