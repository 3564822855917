import { emptySplitApi } from "../emptySplit-api";

export const inventoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    inventoryList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: "/inventories/list",
          method: "post",
          body: data,
        };
      },
    }),
    inventoryGet: builder.query({
      query: (id) => ({ url: `/inventories/get/${id}`, method: "post" }),
    }),
    inventoryImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/inventories/import",
          method: "post",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useInventoryListQuery,
  useInventoryGetQuery,
  useInventoryImportMutation,
} = inventoryApi;
