import Navbar from "../../Navbar/NavbarComponent";
import CustomModal from "../../../shared/components/CustomModal";
import InventoryDetail from "./InventoryDetail";
import { useEffect, useState, useMemo, useRef } from "react";
import Table from "../../../shared/components/Table";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  toastError,
  toastSuccess,
  TOAST_MESSAGE,
} from "../../../shared/constants";
import {
  useInventoryGetQuery,
  useInventoryImportMutation,
  useInventoryListQuery,
} from "../../../Features/inventory/inventory-slice";
import Search from "../../../shared/components/Search";
import { skipToken } from "@reduxjs/toolkit/query";

const Inventory = () => {
  const [search, setSearch] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [inventoryList, setInventoryList] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [inventoryId, setInventoryId]: any = useState();
  const [count, setCount] = useState();
  const [inventoryDetailData, setInventoryDetailData] = useState();
  const iconInput = useRef<any>(null);

  const {
    data,
    isSuccess,
    refetch: inventoryRefetch,
  } = useInventoryListQuery(sort);

  const {
    data: getInventoryData,
    isLoading: getInventoryLoading,
    isSuccess: getInventorySuccess,
  } = useInventoryGetQuery(inventoryId ? inventoryId : skipToken);
  const [
    inventoryImport,
    { isSuccess: inventoryImportSuccess, isLoading: inventoryImportLoading },
  ] = useInventoryImportMutation();

  useEffect(() => {
    if (isSuccess) {
      setInventoryList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (inventoryId !== undefined && getInventorySuccess) {
      setInventoryDetailData(getInventoryData.item);
    }
  }, [inventoryId, getInventoryData, getInventorySuccess]);

  const inventoryImportFlow = (data: any) => {
    inventoryRefetch();
    toastSuccess(data.message);
  };

  const handleClose = () => {
    setShowDetail(false);
  };

  const handleSearch = (val: any) => {
    if (val === "") {
      setSearch(!search);
      setSort({ ...sort, q: undefined, skip: 0 });
    } else {
      setSearch(!search);
      setSort({ ...sort, skip: 0 });
    }
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value });
    if (e.target.value.length === 0) {
      handleSearch(e.target.value);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch(e.target.value);
    }
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const inventoryImportResponse = await inventoryImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (inventoryImportResponse && inventoryImportResponse?.status === true) {
      inventoryImportFlow(inventoryImportResponse);
    }
  };

  const handleInventoryDetails = (e: any, row: any) => {
    setInventoryId(row._id);
    setShowDetail(true);
  };

  const inventoryModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: handleClose,
      className: "btn btn theme-btn",
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: LABELS.CODE_TEXT,
        accessorKey: ACTION_LABEL.CODE,
        isShow: true,
      },
      {
        header: LABELS.DIVISION_TEXT,
        accessorKey: ACTION_LABEL.DIVISION,
        isShow: true,
      },
      {
        header: LABELS.SECTION_TEXT,
        accessorKey: ACTION_LABEL.SECTION,
        isShow: true,
      },
      {
        header: LABELS.DEPARMENT_TEXT,
        accessorKey: ACTION_LABEL.DEPARTMENT,
        isShow: true,
      },
      {
        header: LABELS.ARTICLE_NAME_TEXT,
        accessorKey: ACTION_LABEL.ARTICLE_NAME,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 1`,
        accessorKey: `${ACTION_LABEL.CATEGORY}1`,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 2`,
        accessorKey: `${ACTION_LABEL.CATEGORY}2`,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 3`,
        accessorKey: `${ACTION_LABEL.CATEGORY}3`,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 4`,
        accessorKey: `${ACTION_LABEL.CATEGORY}4`,
        isShow: false,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 5`,
        accessorKey: `${ACTION_LABEL.CATEGORY}5`,
        isShow: false,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 6`,
        accessorKey: `${ACTION_LABEL.CATEGORY}6`,
        isShow: false,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} ${LABELS.ALIAS_TEXT}`,
        accessorKey: `${ACTION_LABEL.DEPARTMENT}${ACTION_LABEL.ALIAS}`,
        isShow: false,
      },
      {
        header: LABELS.HSN_CODE_TEXT,
        accessorKey: ACTION_LABEL.HSN_CODE,
        isShow: false,
      },
      {
        header: LABELS.MATERIAL_TYPE_TEXT,
        accessorKey: ACTION_LABEL.MATERIAL_TYPE,
        isShow: false,
      },
      {
        header: LABELS.MRP_TEXT,
        accessorKey: ACTION_LABEL.MRP,
        isShow: false,
      },
      {
        header: LABELS.RSP_TEXT,
        accessorKey: ACTION_LABEL.RSP,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 1`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}1`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 2`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}2`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 3`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}3`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 4`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}4`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 5`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}5`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 6`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}6`,
        isShow: false,
      },
      {
        header: LABELS.UOM_TEXT,
        accessorKey: ACTION_LABEL.UOM,
        isShow: false,
      },
      {
        header: LABELS.WSP_TEXT,
        accessorKey: ACTION_LABEL.WSP,
        isShow: false,
      },
      {
        header: LABELS.LAST_INWARD_RATE_TEXT,
        accessorKey: ACTION_LABEL.LAST_INWARD_RATE,
        isShow: false,
      },
      {
        header: LABELS.CREATED_ON_TEXT,
        accessorKey: ACTION_LABEL.CREATED_ON,
        isShow: false,
      },
      {
        header: LABELS.LAST_MODIFIED_ON_TEXT,
        accessorKey: ACTION_LABEL.LAST_MODIFIED_ON,
        isShow: false,
      },
      {
        header: LABELS.STANDARD_RATE_TEXT,
        accessorKey: ACTION_LABEL.STANDARD_RATE,
        isShow: false,
      },
      {
        header: LABELS.LAST_STOCK_INWARD_DATE_TEXT,
        accessorKey: ACTION_LABEL.LAST_STOCK_INWARD_RATE,
        isShow: false,
      },
      {
        header: LABELS.BARCODE_TEXT,
        accessorKey: ACTION_LABEL.BARCODE,
        isShow: false,
      },
      {
        header: LABELS.STOCK_POINT_TEXT,
        accessorKey: ACTION_LABEL.STOCK_POINT,
        isShow: false,
      },
      {
        header: LABELS.CLOSING_QTY_TEXT,
        accessorKey: ACTION_LABEL.CLOSING_QTY,
        isShow: false,
      },
    ],
    []
  );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.INVENTORY_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <a
                    href="#"
                    className="me-2 btn theme-btn theme-btn-sm btn-inverse"
                  >
                    <i
                      className={`icon ${icons.ICON_FILTER} me-2 d-inline-block`}
                    ></i>
                    {BUTTON_LABEL.FILTER}
                  </a>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />
                    <a
                      href="#"
                      className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      onClick={handleRef}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={iconInput}
                        onChange={handleCsvImport}
                        accept=".csv"
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                        hidden
                      />
                      {BUTTON_LABEL.IMPORT}
                    </a>
                    <a
                      href="#"
                      className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                    >
                      {BUTTON_LABEL.EXPORT}
                    </a>
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={inventoryList}
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    coloumnDrop={true}
                    count={count}
                    handleRow={handleInventoryDetails}
                    isHandleRowEnabled
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <CustomModal
        show={showDetail}
        close={handleClose}
        size={"lg"}
        message={""}
        modalTitle={LABELS.INVENTORY_MANAGEMENT_DETAILS_TEXT}
        modalButton={inventoryModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <InventoryDetail
          inventoryDetailData={inventoryDetailData}
          getInventoryLoading={getInventoryLoading}
        />
      </CustomModal>
    </>
  );
};

export default Inventory;
