import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { sidebarData } from "../../../shared/constants/resource";
import { Logo } from "../../../App/assets/img";
import { Collapse } from "react-bootstrap";

const Sidebar = () => {
  const location = useLocation();
  const [openId, setOpenId] = useState<any>();
  const [selectedLink, setSelectedLink] = useState("");

  const handleCollaps = (id: any, link: any) => {
    if (id === openId) {
      setOpenId(null);
    } else {
      setSelectedLink(link);
      setOpenId(id);
    }
  };

  return (
    <>
      <div className="sidebar">
        <Link className="navbar-brand" to="/dashboard">
          <img src={Logo} alt="lock" width={350} />
        </Link>
        <nav className="navbar">
          <div className="navbar-nav">
            {sidebarData.map((ele: any, index: any) => {
              return (
                <>
                  <Link
                    to={ele.path}
                    onClick={() => handleCollaps(index, ele.path)}
                    className={`nav-link ${
                      selectedLink === ele.path ? "sidebar-collapse" : ""
                    }`}
                  >
                    <i className={`icon ${ele.icon}`} />

                    {ele.title}
                  </Link>

                  {openId === index && (
                    <div>
                      <Collapse in={openId}>
                        <div id={index}>
                          {ele &&
                            ele?.subItems?.map(
                              (subitem: any, subIndex: any) => {
                                return (
                                  <Link
                                    className={`nav-link ${
                                      location.pathname.includes(subitem.path)
                                        ? "active"
                                        : ""
                                    }`}
                                    to={subitem.path}
                                  >
                                    {subitem.title}
                                  </Link>
                                );
                              }
                            )}
                        </div>
                      </Collapse>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </nav>
      </div>
    </>
  );
  // <div className="sidebar">
  //   <Link className="navbar-brand" to="/dashboard">
  //     <img src={Logo} alt="lock" width={350} />
  //   </Link>
  //   <nav className="navbar">
  //     <div className="navbar-nav">
  //       <Link className="nav-link" to="/dashboard">
  //         <i className="icon icon-dashboard"></i>
  //         Dashboard
  //       </Link>
  //       <Link to="/store-management" className="nav-link">
  //         <i className="icon icon-store"></i>
  //         Store Management
  //       </Link>
  //       <Link className="nav-link" to="#">
  //         <i className="icon icon-brand"></i>
  //         Brand Management
  //       </Link>
  //       <Link className="nav-link" to="/product-management">
  //         <i className="icon icon-product"></i>
  //         Product Management
  //       </Link>
  //       <Link className="nav-link" to="/inventory-management">
  //         <i className="icon icon-inventory"></i>
  //         Inventory Management
  //       </Link>
  //       <Link className="nav-link" to="/vendor-management">
  //         <i className="icon icon-inventory"></i>
  //         Vendor Management
  //       </Link>
  //       <Link className="nav-link" to="#">
  //         <i className="icon icon-oreders"></i>
  //         Order Management
  //       </Link>
  //       <Link className="nav-link" to="/customer-management">
  //         <i className="icon icon-customers"></i>
  //         Customer Management
  //       </Link>
  //       <Link
  //         className="sidebar-collapse"
  //         to={""}
  //         onClick={() => setOpen(!open)}
  //         aria-controls="user_management"
  //         aria-expanded={open}
  //       >
  //         <i className="icon icon-users"></i>
  //         User Management
  //       </Link>
  //       <div>
  //         <Collapse in={open}>
  //           <div id="user_management">
  //             <Link className="nav-link" to="/user-management/users">
  //               Users
  //             </Link>
  //             <Link className="nav-link" to="/user-management/store-coordinator">
  //               Sales Persons
  //             </Link>
  //             <Link className="nav-link" to="/user-management/roles">
  //               Roles
  //             </Link>
  //           </div>
  //         </Collapse>
  //       </div>
  //       <Link className="nav-link" to="#">
  //         <i className="icon icon-reports"></i>
  //         Reports
  //       </Link>
  //     </div>
  //   </nav>
  // </div>
};

export default Sidebar;
