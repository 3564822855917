import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Navigate } from "react-router-dom";
import Sidebar from "../BackOffice/Navbar/SidebarComponent";

const ProtectedLayout = () => {
  const auth = useContext(AuthContext);
  const { authState } = auth;

  if (!authState?.userInfo?.token) {
    return <Navigate replace to={"/login"} />;
  }

  return (
    <>
      <Sidebar />
      <Outlet />
    </>
  );
};

export default ProtectedLayout;
