import { useState } from "react";

export const useDeleteModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>("");
  const [deletedName, setDeletedName] = useState("");
  const [rowData, setRowData] = useState();

  const deleteShowModal = (row: any) => {
    // get name from the the row
    let names = row
      .map(
        (ele: any) => ele.name || ele.firstName || ele.title || ele.imageName
      )
      .join(", ");

    let ids = row.map((ele: any) => ele._id);

    setDeletedName(names);
    setRowData(row);
    setDeleteId(ids);
  };

  const deleteShowModals = () => {
    setShowModal(true);
  };

  const deleteCloseModal = () => {
    setShowModal(false);
  };

  return [
    showModal,
    deletedName,
    deleteId,
    deleteShowModal,
    deleteCloseModal,
    deleteShowModals,
    rowData,
  ];
};
