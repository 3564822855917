import { emptySplitApi } from "../emptySplit-api";

// export interface Store {
//   name: string;
// }

// export const addStore = createAsyncThunk(
//   "addStore",
//   async (data: Store, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post("/stores/create", data);
//       const resData = response.data;
//       return resData;
//     } catch (error) {
//       if (error instanceof AxiosError && error.response) {
//         const errorResponse = error.response.data;

//         return rejectWithValue(errorResponse);
//       }

//       throw error;
//     }
//   }
// );

export const storeApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    storeList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: "/stores/list",
          method: "post",
          body: data,
        };
      },
    }),

    storeById: builder.query({
      query: (id) => ({ url: `/stores/get/${id}`, method: "post" }),
    }),
    storeSearch: builder.query<any, any>({
      query: () => {
        return {
          url: "/stores/search",
          method: "post",
          //   body: data,
        };
      },
    }),
    storeImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/stores/import",
          method: "post",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useStoreListQuery,
  useStoreByIdQuery,
  useStoreSearchQuery,
  useStoreImportMutation,
} = storeApi;
