import { emptySplitApi } from "../emptySplit-api";

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    usersList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: "/users/list",
          method: "post",
          body: data,
        };
      },
    }),
    usersCreate: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/users/create",
          method: "post",
          body: data,
        };
      },
    }),
    usersUpdate: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/users/update/${id}`,
          method: "post",
          body: data,
        };
      },
    }),
    usersDelete: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/users/delete",
          method: "post",
          body: data,
        };
      },
    }),
    usersGet: builder.query<any, any>({
      query: (id: any) => {
        return {
          url: `/users/get/${id}`,
          method: "post",
          // body: id,
        };
      },
    }),
    usersImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/users/import",
          method: "post",
          body: data,
        };
      },
    }),
    usersExport: builder.mutation<any, any>({
      query: () => {
        return {
          url: "/users/export",
          method: "post",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useUsersListQuery,
  useUsersCreateMutation,
  useUsersUpdateMutation,
  useUsersDeleteMutation,
  useUsersGetQuery,
  useUsersImportMutation,
  useUsersExportMutation,
} = userApi;
