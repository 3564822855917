import TextBox from "../../../../shared/components/TextBox";
import TextArea from "../../../../shared/components/TextArea";
import Password from "../../../../shared/components/Password";
import Select from "../../../../shared/components/Select";
import { useEffect } from "react";
import { useUsersGetQuery } from "../../../../Features/user/user-slice";
import { skipToken } from "@reduxjs/toolkit/query";
import CheckBox from "../../../../shared/components/CheckBox/CheckBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";
import { useRolesSearchQuery } from "../../../../Features/role/role-slice";

const AddCustomer = (props: any) => {
  const {
    customerId,
    editMode,
    setEditMode,
    addCustomerData,
    setAddCustomerData,
    handleOnChange,
    errors,
  } = props;

  const {
    data: getCustomerData,
    isLoading: getCustomerLoading,
    isSuccess: getCustomerSuccess,
  } = useUsersGetQuery(customerId ? customerId : skipToken);

  useEffect(() => {
    if (customerId !== undefined && getCustomerSuccess) {
      setEditMode(true);
      setAddCustomerData(getCustomerData.item);
    }
  }, [customerId, getCustomerData, getCustomerSuccess, setAddCustomerData]);

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.SALUTATION}
              placeholder={LABELS.SALUTATION_TEXT}
              id={LABELS.SALUTATION_TEXT}
              label={LABELS.SALUTATION_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.salutation}
              // errorMessage={errors.salutation ? errors.salutation : null}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.FIRSTNAME}
              placeholder={LABELS.FIRSTNAME_TEXT}
              id={LABELS.FIRSTNAME_TEXT}
              label={LABELS.FIRSTNAME_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.firstName}
              errorMessage={errors.firstName ? errors.firstName : null}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.MIDDLENAME}
              placeholder={LABELS.MIDDLE_NAME_TEXT}
              id={LABELS.MIDDLE_NAME_TEXT}
              label={LABELS.MIDDLE_NAME_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.middleName}
              errorMessage={errors.middleName ? errors.middleName : null}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.LASTNAME}
              placeholder={LABELS.LASTNAME_TEXT}
              id={LABELS.LASTNAME_TEXT}
              label={LABELS.LASTNAME_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.lastName}
              errorMessage={errors.lastName ? errors.lastName : null}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={`${ACTION_LABEL.ADDRESS}1`}
              placeholder={`${LABELS.ADDRESS_TEXT} 1`}
              id={`${LABELS.ADDRESS_TEXT} 1`}
              label={`${LABELS.ADDRESS_TEXT} 1`}
              onChange={handleOnChange}
              value={addCustomerData.address1}
              // errorMessage={errors.address1 ? errors.address1 : null}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={`${ACTION_LABEL.ADDRESS}2`}
              placeholder={`${LABELS.ADDRESS_TEXT} 2`}
              id={`${LABELS.ADDRESS_TEXT} 2`}
              label={`${LABELS.ADDRESS_TEXT} 2`}
              onChange={handleOnChange}
              value={addCustomerData.address2}
              // errorMessage={errors.address2 ? errors.address2 : null}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.CITY}
              placeholder={LABELS.CITY_TEXT}
              id={LABELS.CITY_TEXT}
              label={LABELS.CITY_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.city}
              // errorMessage={errors.city ? errors.city : null}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.STATE}
              placeholder={LABELS.STATE_TEXT}
              id={LABELS.STATE_TEXT}
              label={LABELS.STATE_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.state}
              // errorMessage={errors.state ? errors.state : null}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.DISTRICT}
              placeholder={LABELS.DISTRICT_TEXT}
              id={LABELS.DISTRICT_TEXT}
              label={LABELS.DISTRICT_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.district}
              // errorMessage={errors.district ? errors.district : null}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.PIN}
              placeholder={LABELS.PIN_TEXT}
              id={LABELS.PIN_TEXT}
              label={LABELS.PIN_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.pin}
              // errorMessage={errors.pin ? errors.pin : null}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.EMAIL}
              placeholder={LABELS.EMAIL_TEXT}
              id={LABELS.EMAIL_TEXT}
              label={LABELS.EMAIL_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.email}
              errorMessage={errors.email ? errors.email : null}
            />
          </div>
        </div> */}
        <div className="col-6">
          <div className="form-group">
            <TextBox
              // name={ACTION_LABEL.MOBILE_NO}
              name={ACTION_LABEL.MOBILE}
              placeholder={LABELS.MOBILE_NO_TEXT}
              id={LABELS.MOBILE_NO_TEXT}
              label={LABELS.MOBILE_NO_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.mobile}
              // value={addCustomerData.mobileNo}
              errorMessage={errors.mobile ? errors.mobile : null}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.ISD_CODE}
              placeholder={LABELS.ISD_CODE_TEXT}
              id={LABELS.ISD_CODE_TEXT}
              label={LABELS.ISD_CODE_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.isdCode}
              errorMessage={errors.isdCode ? errors.isdCode : null}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.GENDER}
              placeholder={LABELS.GENDER_TEXT}
              id={LABELS.GENDER_TEXT}
              label={LABELS.GENDER_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.gender}
              errorMessage={errors.gender ? errors.gender : null}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.DOB}
              placeholder={LABELS.DOB_TEXT}
              id={LABELS.DOB_TEXT}
              label={LABELS.DOB_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.dob}
              errorMessage={errors.dob ? errors.dob : null}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.RECIEVE_MESSAGE}
              placeholder={LABELS.RECIEVE_MESSAGE_TEXT}
              id={LABELS.RECIEVE_MESSAGE_TEXT}
              label={LABELS.RECIEVE_MESSAGE_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.receiveMessage}
              errorMessage={
                errors.receiveMessage ? errors.receiveMessage : null
              }
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.PREF_COMMUNICATION_MODE}
              placeholder={LABELS.PREF_COMMUNICATION_MODE_TEXT}
              id={LABELS.PREF_COMMUNICATION_MODE_TEXT}
              label={LABELS.PREF_COMMUNICATION_MODE_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.prefCommuniCationMode}
              errorMessage={
                errors.prefCommuniCationMode
                  ? errors.prefCommuniCationMode
                  : null
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.REMARKS}
              placeholder={LABELS.REMARKS_TEXT}
              id={LABELS.REMARKS_TEXT}
              label={LABELS.REMARKS_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.remarks}
              // errorMessage={errors.remarks ? errors.remarks : null}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.GST_STATE_NAME}
              placeholder={LABELS.GST_STATE_NAME_TEXT}
              id={LABELS.GST_STATE_NAME_TEXT}
              label={LABELS.GST_STATE_NAME_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.gstStateName}
              // errorMessage={errors.gstStateName ? errors.gstStateName : null}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.GST_IN}
              placeholder={LABELS.GST_IN_TEXT}
              id={LABELS.GST_IN_TEXT}
              label={LABELS.GST_IN_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.gstin}
              // errorMessage={errors.gstin ? errors.gstin : null}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
