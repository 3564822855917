import { createContext, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext<any>(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: any) => {
  // const [userLogout] = useLogoutMutation();
  // const location = useLocation();
  // const {
  //   data: user,
  //   isLoading: userInfoLoading,
  //   isSuccess,
  //   isError: userInfoError,
  // } = useUserInfoQuery("", {
  //   skip: location.pathname === "/Auth" ? true : false,
  // });

  // const navigate = useNavigate();

  const userData = localStorage.getItem("userInfo");
  const [authState, setAuthState] = useState({
    userInfo: userData !== null ? JSON.parse(userData) : null,
    // isAuthenticated: false,
  });

  // useEffect(() => {
  //   if (user) {
  //     setAuthState({
  //       userInfo: user,
  //       isAuthenticated: true,
  //     });
  //   }
  // }, [user]);

  const setAuthInfo = ({ userInfo }: any) => {
    setAuthState({
      userInfo,
      // isAuthenticated: userInfo && userInfo._id ? true : false,
    });
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo: any) => setAuthInfo(authInfo),
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
