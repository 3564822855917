import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const storeApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    storeList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.STORES}/list`,
          method: "post",
          body: data,
        };
      },
    }),

    storeById: builder.query({
      query: (id) => ({ url: `/${API_URL.STORES}/get/${id}`, method: "post" }),
    }),
    storeSearch: builder.query<any, any>({
      query: () => {
        return {
          url: `/${API_URL.STORES}/search`,
          method: "post",
          //   body: data,
        };
      },
    }),
    storeImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.STORES}/import`,
          method: "post",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useStoreListQuery,
  useStoreByIdQuery,
  useStoreSearchQuery,
  useStoreImportMutation,
} = storeApi;
