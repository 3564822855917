export interface SimpleButtonProps {
  text?: string | number | null | undefined | boolean | React.ReactNode | any;
  onClick?: () => void | any;
  className?: string | any;
  children?: React.ReactNode | any;
  disabled?: boolean;
}

export const SimpleButton: React.FC<SimpleButtonProps> = (props) => {
  const { text, onClick, className, children, disabled } = props;

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled ? true : false}
    >
      {text && <span>{text}</span>}
      {children}
    </button>
  );
};
