import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
} from "../../../shared/constants";
import Navbar from "../../Navbar/NavbarComponent/Navbar";
import { Link } from "react-router-dom";
import Table from "../../../shared/components/Table";
import Search from "../../../shared/components/Search";
import { useEffect, useMemo, useState } from "react";
import { useProductListQuery } from "../../../Features/product/product-slice";
import SimpleButton from "../../../shared/components/Button/Button";

const Product = () => {
  const [search, setSearch] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [count, setCount] = useState();

  const { data, isSuccess, error, refetch } = useProductListQuery(sort);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setProductList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  const handleProductDetails = (e: any, row: any) => {};

  const handleSearch = () => {
    // if (val === "") {
    //   setSearch(!search);
    //   setSort({ ...sort, q: undefined, skip: 0 });
    // } else {
    //   setSearch(!search);
    //   setSort({ ...sort, skip: 0 });
    // }

    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value });
    // if (e.target.value.length === 0) {
    //   handleSearch(e.target.value);
    // }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
      // handleSearch(e.target.value);
    }
  };

  const storeModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: handleClose,
      className: "btn btn theme-btn",
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: LABELS.BARCODE_TEXT,
        accessorKey: ACTION_LABEL.BARCODE,
        cell: (row: any) => (
          <>
            <div
              className="collapsible-toggle"
              style={{
                paddingLeft: `${row.row.depth * 40}px`,
                cursor: "pointer",
              }}
            >
              {row.row.getCanExpand() ? (
                <Link
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering parent row click
                    row.row.getToggleExpandedHandler()();
                  }}
                  style={{ cursor: "pointer" }}
                  to=""
                >
                  {row.row.getIsExpanded() ? (
                    <i className={`icon ${icons.ICON_CHEVRON_UP}`} />
                  ) : (
                    <i className={`icon ${icons.ICON_CHEVRON_DOWN}`} />
                  )}
                </Link>
              ) : (
                ""
              )}
              {row.cell.getValue()}
            </div>
          </>
        ),
        isShow: true,
      },
      {
        header: LABELS.ITEM_NAME_TEXT,
        accessorKey: ACTION_LABEL.ITEM_NAME,
        isShow: true,
      },
      {
        header: LABELS.DIVISION_TEXT,
        accessorKey: ACTION_LABEL.DIVISION,
        isShow: true,
      },
      {
        header: LABELS.SECTION_TEXT,
        accessorKey: ACTION_LABEL.SECTION,
        isShow: true,
      },
      {
        header: LABELS.ARTICLE_NAME_TEXT,
        accessorKey: ACTION_LABEL.ARTICLE_NAME,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 1`,
        accessorKey: `${ACTION_LABEL.CATEGORY}1`,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 2`,
        accessorKey: `${ACTION_LABEL.CATEGORY}2`,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 3`,
        accessorKey: `${ACTION_LABEL.CATEGORY}3`,
        isShow: false,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 4`,
        accessorKey: `${ACTION_LABEL.CATEGORY}4`,
        isShow: false,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 5`,
        accessorKey: `${ACTION_LABEL.CATEGORY}5`,
        isShow: false,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 6`,
        accessorKey: `${ACTION_LABEL.CATEGORY}6`,
        isShow: false,
      },
      {
        header: LABELS.HSN_CODE_TEXT,
        accessorKey: ACTION_LABEL.HSN_CODE,
        isShow: false,
      },
      {
        header: LABELS.SITE_STOCK_TEXT,
        accessorKey: ACTION_LABEL.SITE_STOCK,
        isShow: false,
      },
    ],
    [sort]
  );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.PRODUCT_MANAGEMENT_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <SimpleButton
                    className={"me-2 btn theme-btn theme-btn-sm btn-inverse"}
                    btnIcon={icons.ICON_FILTER}
                    btnIconClass={"me-2 d-inline-block"}
                    text={BUTTON_LABEL.FILTER}
                  />
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />
                    {/* <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleShow}
                      text={BUTTON_LABEL.EXPORT}
                    /> */}
                  </div>
                </div>
              </div>

              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={productList}
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    coloumnDrop={true}
                    count={count}
                    handleRow={handleProductDetails}
                    isHandleRowEnabled
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );

  // return (
  //   <div className="full-height">
  //     <Navbar title={LABELS.PRODUCT_MANAGEMENT_TEXT} />
  //     {/* <Sidebar /> */}
  //     <main className="main-content">
  //       <div className="pt-3">
  //         <div className="container-fluid">
  //           <div className="row table-top-actions">
  //             <div className="col-12 col-md-4 d-flex align-items-center">
  //               <a
  //                 href="javascript:"
  //                 className="me-2 btn theme-btn theme-btn-sm btn-inverse"
  //               >
  //                 <i className="icon icon-filter me-2 d-inline-block"></i>
  //                 Filter
  //               </a>
  //             </div>
  //             <div className="col-12 col-md-8 d-flex justify-content-end">
  //               <div className="d-flex align-items-center row w-100 mx-m-0">
  //                 <div className="col-12 col-md-7 ms-auto">
  //                   <div className="form-group mb-0 search-input position-relative ">
  //                     <div className="icon">
  //                       <i className="icon icon-search"></i>
  //                     </div>
  //                     <input
  //                       type="text"
  //                       className="form-control bg-white"
  //                       placeholder="Search"
  //                     />
  //                   </div>
  //                 </div>
  //                 <a
  //                   href="#"
  //                   className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
  //                 >
  //                   Export
  //                 </a>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="card custom-card card-full mt-3">
  //             <div className="card-body p-0">
  //               <div className="row">
  //                 <div className="col-12">
  //                   <div className="table-responsive">
  //                     <table className="table table-header-shadow cell-1-small mb-0">
  //                       <thead>
  //                         <tr>
  //                           <th></th>
  //                           <th>Salutation</th>
  //                           <th>First Name</th>
  //                           <th>Middle Name</th>
  //                           <th>Last Name</th>
  //                           <th>Address</th>
  //                           <th>City</th>
  //                           <th>State</th>
  //                           <th>
  //                             <div className="dropdown cell-dropdown"></div>
  //                             <Dropdown className="dropdown cell-dropdown float-end">
  //                               <Dropdown.Toggle className="no-btn">
  //                                 <a href="#">
  //                                   <svg
  //                                     xmlns="http://www.w3.org/2000/svg"
  //                                     width="18"
  //                                     height="18.015"
  //                                     viewBox="0 0 18 18.015"
  //                                   >
  //                                     <g
  //                                       id="Group_11176"
  //                                       data-name="Group 11176"
  //                                       transform="translate(-1297.437 -289.429)"
  //                                     >
  //                                       <g
  //                                         id="Rectangle_19126"
  //                                         data-name="Rectangle 19126"
  //                                         transform="translate(1297.437 289.437)"
  //                                         fill="#fff"
  //                                         stroke="#707070"
  //                                         stroke-width="1"
  //                                       >
  //                                         <rect
  //                                           width="18"
  //                                           height="18"
  //                                           stroke="none"
  //                                         ></rect>
  //                                         <rect
  //                                           x="0.5"
  //                                           y="0.5"
  //                                           width="17"
  //                                           height="17"
  //                                           fill="none"
  //                                         ></rect>
  //                                       </g>
  //                                       <line
  //                                         id="Line_75"
  //                                         data-name="Line 75"
  //                                         y2="18.015"
  //                                         transform="translate(1303.434 289.429)"
  //                                         fill="none"
  //                                         stroke="#707070"
  //                                         stroke-width="1"
  //                                       ></line>
  //                                       <line
  //                                         id="Line_76"
  //                                         data-name="Line 76"
  //                                         y2="18.015"
  //                                         transform="translate(1309.439 289.429)"
  //                                         fill="none"
  //                                         stroke="#707070"
  //                                         stroke-width="1"
  //                                       ></line>
  //                                     </g>
  //                                   </svg>
  //                                 </a>
  //                               </Dropdown.Toggle>
  //                               <Dropdown.Menu className="dropdown-menu has-checkbox">
  //                                 <li>
  //                                   <div className="dropdown-item">
  //                                     <div className="custom-checkbox">
  //                                       <input
  //                                         className="custom-control-input"
  //                                         type="checkbox"
  //                                         id="cat1"
  //                                         value="option1"
  //                                       />
  //                                       <label
  //                                         className="custom-control-label"
  //                                         htmlFor="cat1"
  //                                       >
  //                                         Category 1
  //                                       </label>
  //                                     </div>
  //                                   </div>
  //                                 </li>
  //                                 <li>
  //                                   <div className="dropdown-item">
  //                                     <div className="custom-checkbox">
  //                                       <input
  //                                         className="custom-control-input"
  //                                         type="checkbox"
  //                                         id="cat2"
  //                                         value="option1"
  //                                       />
  //                                       <label
  //                                         className="custom-control-label"
  //                                         htmlFor="cat2"
  //                                       >
  //                                         Category 2
  //                                       </label>
  //                                     </div>
  //                                   </div>
  //                                 </li>
  //                                 <li>
  //                                   <div className="dropdown-item">
  //                                     <div className="custom-checkbox">
  //                                       <input
  //                                         className="custom-control-input"
  //                                         type="checkbox"
  //                                         id="cat3"
  //                                         value="option1"
  //                                       />
  //                                       <label
  //                                         className="custom-control-label"
  //                                         htmlFor="cat3"
  //                                       >
  //                                         Category 3
  //                                       </label>
  //                                     </div>
  //                                   </div>
  //                                 </li>
  //                                 <li>
  //                                   <div className="dropdown-item">
  //                                     <div className="custom-checkbox">
  //                                       <input
  //                                         className="custom-control-input"
  //                                         type="checkbox"
  //                                         id="cat4"
  //                                         value="option1"
  //                                       />
  //                                       <label
  //                                         className="custom-control-label"
  //                                         htmlFor="cat4"
  //                                       >
  //                                         Category 4
  //                                       </label>
  //                                     </div>
  //                                   </div>
  //                                 </li>
  //                                 <li>
  //                                   <div className="dropdown-item">
  //                                     <div className="custom-checkbox">
  //                                       <input
  //                                         className="custom-control-input"
  //                                         type="checkbox"
  //                                         id="cat5"
  //                                         value="option1"
  //                                       />
  //                                       <label
  //                                         className="custom-control-label"
  //                                         htmlFor="cat5"
  //                                       >
  //                                         Category 5
  //                                       </label>
  //                                     </div>
  //                                   </div>
  //                                 </li>
  //                                 <li>
  //                                   <div className="dropdown-item">
  //                                     <div className="custom-checkbox">
  //                                       <input
  //                                         className="custom-control-input"
  //                                         type="checkbox"
  //                                         id="cat6"
  //                                         value="option1"
  //                                       />
  //                                       <label
  //                                         className="custom-control-label"
  //                                         htmlFor="cat6"
  //                                       >
  //                                         Category 6
  //                                       </label>
  //                                     </div>
  //                                   </div>
  //                                 </li>
  //                               </Dropdown.Menu>
  //                             </Dropdown>
  //                           </th>
  //                         </tr>
  //                       </thead>
  //                       <tbody>
  //                         <tr>
  //                           <td className="collapsible-toggle">
  //                             <svg
  //                               xmlns="http://www.w3.org/2000/svg"
  //                               width="10.724"
  //                               height="6.179"
  //                               viewBox="0 0 10.724 6.179"
  //                             >
  //                               <path
  //                                 id="Path_4835"
  //                                 data-name="Path 4835"
  //                                 d="M4.631.1a.351.351,0,0,1,.5.5L.814,4.912,5.127,9.225a.351.351,0,0,1-.5.5L.1,5.193a.35.35,0,0,1-.1-.28.35.35,0,0,1,.1-.282Z"
  //                                 transform="translate(0.45 5.728) rotate(-90)"
  //                                 fill="#3e2215"
  //                                 stroke="#3e2215"
  //                                 stroke-width="0.9"
  //                               />
  //                             </svg>
  //                           </td>
  //                           <td>Mr.</td>
  //                           <td>John</td>
  //                           <td>Cris </td>
  //                           <td>Doe</td>
  //                           <td>San house rydot infotech </td>
  //                           <td>Ahmedabad</td>
  //                           <td>Gujarat</td>
  //                           <td>&nbsp;</td>
  //                         </tr>
  //                         <Link
  //                           className="collapse is-open table-row"
  //                           to={"/product-management/add"}
  //                         >
  //                           <td> </td>
  //                           <td>Mr.</td>
  //                           <td>John</td>
  //                           <td>Cris </td>
  //                           <td>Doe</td>
  //                           <td>San house rydot infotech</td>
  //                           <td>Ahmedabad</td>
  //                           <td>Gujarat</td>
  //                           <td>&nbsp;</td>
  //                         </Link>
  //                         <tr>
  //                           <td className="collapsible-toggle">
  //                             <svg
  //                               xmlns="http://www.w3.org/2000/svg"
  //                               width="10.724"
  //                               height="6.179"
  //                               viewBox="0 0 10.724 6.179"
  //                             >
  //                               <path
  //                                 id="Path_4835"
  //                                 data-name="Path 4835"
  //                                 d="M4.631.1a.351.351,0,0,1,.5.5L.814,4.912,5.127,9.225a.351.351,0,0,1-.5.5L.1,5.193a.35.35,0,0,1-.1-.28.35.35,0,0,1,.1-.282Z"
  //                                 transform="translate(0.45 5.728) rotate(-90)"
  //                                 fill="#3e2215"
  //                                 stroke="#3e2215"
  //                                 stroke-width="0.9"
  //                               />
  //                             </svg>
  //                           </td>
  //                           <td>Mr.</td>
  //                           <td>John</td>
  //                           <td>Cris </td>
  //                           <td>Doe</td>
  //                           <td>San house rydot infotech</td>
  //                           <td>Ahmedabad</td>
  //                           <td>Gujarat</td>
  //                           <td>&nbsp;</td>
  //                         </tr>
  //                         <tr>
  //                           <td className="collapsible-toggle">
  //                             <svg
  //                               xmlns="http://www.w3.org/2000/svg"
  //                               width="10.724"
  //                               height="6.179"
  //                               viewBox="0 0 10.724 6.179"
  //                             >
  //                               <path
  //                                 id="Path_4835"
  //                                 data-name="Path 4835"
  //                                 d="M4.631.1a.351.351,0,0,1,.5.5L.814,4.912,5.127,9.225a.351.351,0,0,1-.5.5L.1,5.193a.35.35,0,0,1-.1-.28.35.35,0,0,1,.1-.282Z"
  //                                 transform="translate(0.45 5.728) rotate(-90)"
  //                                 fill="#3e2215"
  //                                 stroke="#3e2215"
  //                                 stroke-width="0.9"
  //                               />
  //                             </svg>
  //                           </td>
  //                           <td>Mr.</td>
  //                           <td>John</td>
  //                           <td>Cris </td>
  //                           <td>Doe</td>
  //                           <td>San house rydot infotech</td>
  //                           <td>Ahmedabad</td>
  //                           <td>Gujarat</td>
  //                           <td>&nbsp;</td>
  //                         </tr>
  //                         <tr>
  //                           <td className="collapsible-toggle">
  //                             <svg
  //                               xmlns="http://www.w3.org/2000/svg"
  //                               width="10.724"
  //                               height="6.179"
  //                               viewBox="0 0 10.724 6.179"
  //                             >
  //                               <path
  //                                 id="Path_4835"
  //                                 data-name="Path 4835"
  //                                 d="M4.631.1a.351.351,0,0,1,.5.5L.814,4.912,5.127,9.225a.351.351,0,0,1-.5.5L.1,5.193a.35.35,0,0,1-.1-.28.35.35,0,0,1,.1-.282Z"
  //                                 transform="translate(0.45 5.728) rotate(-90)"
  //                                 fill="#3e2215"
  //                                 stroke="#3e2215"
  //                                 stroke-width="0.9"
  //                               />
  //                             </svg>
  //                           </td>
  //                           <td>Mr.</td>
  //                           <td>John</td>
  //                           <td>Cris </td>
  //                           <td>Doe</td>
  //                           <td>San house rydot infotech</td>
  //                           <td>Ahmedabad</td>
  //                           <td>Gujarat</td>
  //                           <td>&nbsp;</td>
  //                         </tr>
  //                       </tbody>
  //                     </table>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="row align-items-center pagination">
  //                 <div className="col-12 col-md-4">
  //                   <div className="form-group mb-0">
  //                     Show
  //                     <select
  //                       name=""
  //                       id=""
  //                       className="form-control w-auto d-inline-block"
  //                     >
  //                       <option value="10">10</option>
  //                       <option value="50">50</option>
  //                       <option value="100">100</option>
  //                     </select>
  //                     &nbsp; Entries
  //                   </div>
  //                 </div>
  //                 <div className="col-12 col-md-4">
  //                   <div className="form-group mb-0 text-center">
  //                     Showing 1 to 10 of 223 entries
  //                   </div>
  //                 </div>
  //                 <div className="col-12 col-md-4">
  //                   <nav>
  //                     <ul className="pagination mb-0 justify-content-end">
  //                       <li className="page-item">
  //                         <a className="page-link" href="#">
  //                           &lt;
  //                         </a>
  //                       </li>
  //                       <li className="page-item active">
  //                         <a className="page-link" href="#">
  //                           1
  //                         </a>
  //                       </li>
  //                       <li className="page-item">
  //                         <a className="page-link" href="#">
  //                           2
  //                         </a>
  //                       </li>
  //                       <li className="page-item">
  //                         <a className="page-link" href="#">
  //                           3
  //                         </a>
  //                       </li>
  //                       <li className="page-item">
  //                         <a className="page-link" href="#">
  //                           &gt;
  //                         </a>
  //                       </li>
  //                     </ul>
  //                   </nav>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </main>
  //   </div>
  // );
};

export default Product;
