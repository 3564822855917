// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (max-width:767px) {

  .top-nav,
  main.main-content {
    margin-left: 0px;
  }

  .top-nav .navbar-nav {
    flex-direction: row;
  }

  .top-nav .user-profile>div {
    display: none;
  }

  .search-form {
    display: none !important;
  }

  .right-navbar a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .mx-m-0{
    margin-left: 0px;
    margin-right: 0px;
  }
  .mx-m-0 .col-12{
    padding: 0px;
  }
  .table-top-actions .col-md-4,
  .table-top-actions .search-input{
    margin-bottom: 10px !important;
  }
  .table-header-shadow th, .table-header-shadow td{
    white-space: nowrap;
  }
  .sidebar{
    left: -100%;
  }
  .login-wrapper{
    padding: 0px 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/App/assets/css/responsive.css"],"names":[],"mappings":";AACA;;EAEE;;IAEE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,oBAAoB;IACpB,qBAAqB;EACvB;EACA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;EACA;IACE,YAAY;EACd;EACA;;IAEE,8BAA8B;EAChC;EACA;IACE,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["\n@media (max-width:767px) {\n\n  .top-nav,\n  main.main-content {\n    margin-left: 0px;\n  }\n\n  .top-nav .navbar-nav {\n    flex-direction: row;\n  }\n\n  .top-nav .user-profile>div {\n    display: none;\n  }\n\n  .search-form {\n    display: none !important;\n  }\n\n  .right-navbar a {\n    padding-left: 0.5rem;\n    padding-right: 0.5rem;\n  }\n  .mx-m-0{\n    margin-left: 0px;\n    margin-right: 0px;\n  }\n  .mx-m-0 .col-12{\n    padding: 0px;\n  }\n  .table-top-actions .col-md-4,\n  .table-top-actions .search-input{\n    margin-bottom: 10px !important;\n  }\n  .table-header-shadow th, .table-header-shadow td{\n    white-space: nowrap;\n  }\n  .sidebar{\n    left: -100%;\n  }\n  .login-wrapper{\n    padding: 0px 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
