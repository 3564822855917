import { useEffect, useState } from "react";
import Navbar from "../../../Navbar/NavbarComponent/Navbar";
import { BUTTON_LABEL, LABELS } from "../../../../shared/constants";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useOrderGetQuery } from "../../../../Features/order/order-slice";

const OrderDetail = (props: any) => {
  const {} = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [orderId, setOrderId]: any = useState(id);
  const [orderDetailData, setOrderDetailData] = useState();

  const {
    data: getOrderData,
    isLoading: getOrderLoading,
    isSuccess: getOrderSuccess,
  } = useOrderGetQuery(orderId ? orderId : skipToken);

  useEffect(() => {
    if (orderId !== undefined && getOrderSuccess) {
      setOrderDetailData(getOrderData.item);
    }
  }, [orderId, getOrderData, getOrderSuccess]);

  const closeOrderDetails = () => {
    navigate("/order-management");
  };

  return (
    <>
      {/* {!getOrderLoading && orderDetailData !== undefined ? ( */}
      {/* <> */}
      <div className="full-height">
        <Navbar title={`${LABELS.ORDER_INFORMATION_TEXT} - #12312`} />
        {/* <Sidebar /> */}
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card custom-card card-full mt-3 has-radius">
                    <div className="card-body">
                      <p className="font-20 section-title">
                        {LABELS.ORDER_DETAILS_TEXT}
                      </p>
                      <div className="card section-card mb-3">
                        <div className="card-body">
                          <p>
                            <strong>{LABELS.ITEM_TEXT} -</strong>
                            #12312 | 2345 | M | Red
                          </p>
                          <div className="row mb-3 align-items-center">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Order Date
                                </label>
                                <p className="mb-0 text-muted">25/08/2024</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Destination Site
                                </label>
                                <p className="mb-0 text-muted">KORA</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Trade Group
                                </label>
                                <p className="mb-0 text-muted">#212582</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Delivery Date
                                </label>
                                <p className="mb-0 text-muted">25/08/2024</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Lot No.
                                </label>
                                <p className="mb-0 text-muted">40</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Price
                                </label>
                                <p className="mb-0 text-muted">$56</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <p>
                              <strong>Sub Assembly item</strong>
                            </p>
                            <div className="col-md-4">
                              <div className="slug-custom-dropdown">
                                <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                  <span>Pant </span>
                                  <span>
                                    <i className="icon-caret-down"></i>
                                  </span>
                                </div>
                                <div className="custom-dropdown-menu">
                                  <div className="item-card">
                                    <div className="item-body">
                                      <div className="populate-values">
                                        <div className="value">
                                          <label htmlFor="">L</label>
                                          <span className="label-box">
                                            31.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">SH</label>
                                          <span className="label-box">
                                            19.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">SL</label>
                                          <span className="label-box">
                                            26.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">CH</label>
                                          <span className="label-box">48</span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">ST</label>
                                          <span className="label-box">47</span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">H</label>
                                          <span className="label-box">
                                            47.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">N</label>
                                          <span className="label-box">
                                            16.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">C.B</label>
                                          <span className="label-box">18</span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">C.F</label>
                                          <span className="label-box">17</span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">L</label>
                                          <span className="label-box">
                                            31.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">SH</label>
                                          <span className="label-box">
                                            19.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">SL</label>
                                          <span className="label-box">
                                            26.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">CH</label>
                                          <span className="label-box">48</span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">ST</label>
                                          <span className="label-box">47</span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">H</label>
                                          <span className="label-box">
                                            47.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">N</label>
                                          <span className="label-box">
                                            16.5
                                          </span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">C.B</label>
                                          <span className="label-box">18</span>
                                        </div>
                                        <div className="value">
                                          <label htmlFor="">C.F</label>
                                          <span className="label-box">17</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="slug-custom-dropdown">
                                <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                  <span>Duptta </span>
                                  <span>
                                    <i className="icon-caret-down"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="slug-custom-dropdown">
                                <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                  <span>Kurta </span>
                                  <span>
                                    <i className="icon-caret-down"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h6 className="my-3 section-title">
                        Customer Informarmation
                      </h6>
                      <div className="card section-card mb-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-3 col-12">
                              <div className="form-group mb-0">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Name
                                </label>
                                <p className="mb-0 text-muted">Hemanshu</p>
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group mb-0">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Phone
                                </label>
                                <p className="mb-0 text-muted">8562352412</p>
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group mb-0">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Email
                                </label>
                                <p className="mb-0 text-muted">
                                  hemnashum@gmail.com
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group mb-0">
                                <label
                                  htmlFor=""
                                  className="text-black font-weight-600"
                                >
                                  Address
                                </label>
                                <p className="mb-0 text-muted">
                                  Ahmedabad, 852635,Bopal
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h6 className="my-3 section-title">
                        Tracking Informarmation
                      </h6>
                      <div className="tracking-status">
                        <div className="item">
                          <div className="item-title">Shervani</div>
                        </div>
                        <div className="track-stepper">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <div className="d-flex w-max-content justify-content-center flex-wrap">
                                <div className="track-status done">
                                  <i className="icon-check"></i>
                                </div>
                                <div className="w-100 text-center">Cutting</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex w-max-content justify-content-center flex-wrap">
                                <div className="track-status in-progress">
                                  <i className="icon-refresh"></i>
                                </div>
                                <div className="w-100 text-center">
                                  Embrooidry
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex w-max-content justify-content-center flex-wrap">
                                <div className="track-status"></div>
                                <div className="w-100 text-center">
                                  Stitching
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex w-max-content justify-content-center flex-wrap">
                                <div className="track-status"></div>
                                <div className="w-100 text-center">
                                  Finishing
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="slug-custom-dropdown mt-4 d-inline-block">
                          <div className="toggle d-flex align-items-center w-100 justify-content-between primary-bg text-white">
                            <span>Sub Assembly item</span>
                            <span className="ms-3">
                              <i className="icon-caret-down"></i>
                            </span>
                          </div>
                        </div>
                        <div className="row mt-4 align-items-center assembally-attr">
                          <div className="col-md-3">
                            <label htmlFor="">Pant</label>
                          </div>
                          <div className="col-md-6">
                            <div className="track-stepper">
                              <ul className="list-unstyled my-0">
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status done">
                                      <i className="icon-check"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Cutting
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status in-progress">
                                      <i className="icon-refresh"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Embrooidry
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status">
                                      <i className="icon"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Stitching
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status">
                                      <i className="icon"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Finishing
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 align-items-center assembally-attr">
                          <div className="col-md-3">
                            <label htmlFor="">Pant</label>
                          </div>
                          <div className="col-md-6">
                            <div className="track-stepper">
                              <ul className="list-unstyled my-0">
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status done">
                                      <i className="icon-check"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Cutting
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status in-progress">
                                      <i className="icon-refresh"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Embrooidry
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status">
                                      <i className="icon"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Stitching
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status">
                                      <i className="icon"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Finishing
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-4">
                          <span className="font-18">Confirm this Order :</span>
                          <Button variant="btn success-btn theme-btn theme-btn-sm btn-inverse ms-2 d-inline-block">
                            Confirm
                          </Button>
                        </div>
                        <div className="row mt-5">
                          <div className="col-12 text-center">
                            <Button
                              variant="btn theme-btn theme-btn-sm btn-inverse"
                              onClick={closeOrderDetails}
                            >
                              {BUTTON_LABEL.CANCEL}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* </> */}
      {/* ) : (
        "Loading..."
      )} */}
    </>
  );
};

export default OrderDetail;
