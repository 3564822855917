import { useEffect, useMemo, useRef, useState } from "react";
import Navbar from "../../Navbar/NavbarComponent";
import CustomModal from "../../../shared/components/CustomModal";
import AddUser from "./AddUser";
import UserDetail from "./UserDetail";
import {
  useUsersListQuery,
  useUsersGetQuery,
  useUsersCreateMutation,
  useUsersUpdateMutation,
  useUsersDeleteMutation,
  useUsersImportMutation,
  useUsersExportMutation,
} from "../../../Features/user/user-slice";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  TOAST_MESSAGE,
  toastError,
  toastSuccess,
} from "../../../shared/constants";
import Search from "../../../shared/components/Search";
import Table from "../../../shared/components/Table";
import { skipToken } from "@reduxjs/toolkit/query";
import { useDeleteModal } from "../../../shared/utils/hooks/useDeleteModal";
import { listCheck } from "../../../shared/helperFunc/listCheck";
import CustomSwitch from "../../../shared/components/CustomSwitch";
import { userValidation } from "../../../shared/validation/user-validation ";
import authProvider from "../../../shared/config/authProvider";
import SimpleButton from "../../../shared/components/Button/Button";

const User = () => {
  const downloadPath = process.env.REACT_APP_BACKEND_URL + "/uploads/";
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [search, setSearch] = useState(false);
  const [count, setCount] = useState();
  const [multiDelete, setMultiDelete] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userId, setUserId]: any = useState();
  const [userDetailData, setUserDetailData] = useState();
  const [addUserData, setAddUserData]: any = useState({
    name: "",
    email: "",
    phone: "",
    designation: "",
    address: "",
    state: "",
    city: "",
    password: "",
    role: "",
    // store: "",
    isActive: true,
  });
  const [editMode, setEditMode] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const iconInput = useRef<any>(null);

  const [
    showModal,
    deletedName,
    deleteId,
    deleteShowModal,
    deleteCloseModal,
    deleteShowModals,
  ] = useDeleteModal();

  const {
    data,
    isSuccess,
    error,
    refetch: userRefetch,
  } = useUsersListQuery(sort);

  const [userCreate, { isSuccess: userCreateSuccess }] =
    useUsersCreateMutation();

  const [userUpdate, { isSuccess: userUpdateSuccess }] =
    useUsersUpdateMutation();

  const [
    userDelete,
    { isSuccess: userDeleteSuccess, isLoading: userDeleteLoading },
  ] = useUsersDeleteMutation();

  const [
    userImport,
    { isSuccess: userImportSuccess, isLoading: userImportLoading },
  ] = useUsersImportMutation();

  const [
    userExport,
    { isSuccess: userExportSuccess, isLoading: userExportLoading },
  ] = useUsersExportMutation();

  const {
    data: getUserData,
    isLoading: getUserLoading,
    isSuccess: getUserSuccess,
  } = useUsersGetQuery(userId ? userId : skipToken);

  useEffect(() => {
    if (isSuccess) {
      setUserList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (userId !== undefined && getUserSuccess) {
      setUserDetailData({
        ...getUserData.item,
        role: getUserData?.role?.name,
        // store: getUserData?.item?.store?.name,
      });
    }
  }, [userId, getUserData, getUserSuccess]);

  const closeUserModal = () => {
    setShowUserModal(false);
    setUserId("");
    setAddUserData({});
    setEditMode(false);
    setResetPassword(false);
    setErrors({});
  };

  const handleOnChange = (e: any) => {
    setAddUserData({ ...addUserData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const closeDetail = () => {
    setUserId("");
    setShowDetail(false);
  };

  const handleUserModal = () => {
    setShowUserModal(true);
  };

  const handleSearch = () => {
    // if (val === "") {
    //   setSearch(!search);
    //   setSort({ ...sort, q: undefined, skip: 0 });
    // } else {
    //   setSearch(!search);
    //   setSort({ ...sort, skip: 0 });
    // }

    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value });
    // if (e.target.value.length === 0) {
    //   handleSearch(e.target.value);
    // }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
      // handleSearch(e.target.value);
    }
  };

  const userAddFlow = async (data: any) => {
    toastSuccess(data.message);
    setUserId("");
    setEditMode(false);
    setErrors({});
    setShowUserModal(false);
    setAddUserData({});
    userRefetch();
  };

  const userUpdateFlow = async (data: any) => {
    toastSuccess(data.message);
    setUserId("");
    setEditMode(false);
    setErrors({});
    setShowUserModal(false);
    setAddUserData({});
    userRefetch();
  };

  const userDeleteFlow = async (data: any) => {
    toastSuccess(data.message);
    deleteCloseModal();
    deleteShowModal([]);
    removeMultiDelete();
    setShowUserModal(false);
    userRefetch();
  };

  const handleSave = async () => {
    const { formErrors, formValid } = userValidation(
      addUserData,
      editMode ? resetPassword : true
    );
    if (formValid) {
      if (editMode) {
        const userUpdateResponse = await userUpdate({
          id: userId,
          data: addUserData,
        })
          .unwrap()
          .catch((err: any) => {
            toastError(err.data?.errors[0]?.msg);
          });
        if (userUpdateResponse && userUpdateResponse?.status === true) {
          userUpdateFlow(userUpdateResponse);
        }
      } else {
        const userAddResponse = await userCreate(addUserData)
          .unwrap()
          .catch((err: any) => {
            toastError(err.data?.errors[0]?.msg);
          });
        if (userAddResponse && userAddResponse?.status === true) {
          userAddFlow(userAddResponse);
        }
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleSwitchChange = async (id: any, isActive: any) => {
    const userUpdateResponse = await userUpdate({
      id: id,
      data: { isActive: !isActive },
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (userUpdateResponse && userUpdateResponse?.status === true) {
      userUpdateFlow(userUpdateResponse);
    }
  };

  const addUserModalButton = [
    {
      text: BUTTON_LABEL.SAVE,
      action: handleSave,
      className: "btn theme-btn",
    },
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeUserModal,
      className: "btn btn theme-btn",
    },
  ];

  const userDetailModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeDetail,
      className: "btn btn theme-btn",
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: LABELS.FULLNAME_TEXT,
        accessorKey: ACTION_LABEL.NAME,
        isShow: true,
      },
      {
        header: LABELS.EMAIL_TEXT,
        accessorKey: ACTION_LABEL.EMAIL,
        isShow: true,
      },
      {
        header: LABELS.ROLE_TEXT,
        accessorKey: `${ACTION_LABEL.ROLE}.${ACTION_LABEL.NAME}`,
        isShow: true,
      },
      // {
      //   header: "Store",
      //   accessorKey: `${ACTION_LABEL.STORE}.${ACTION_LABEL.NAME}`,
      //   isShow: true,
      // },
      {
        header: LABELS.STATUS_TEXT,
        cell: ({ row }: any) => CustomSwitch(row, handleSwitchChange),
        isShow: true,
      },
    ],
    [sort]
  );

  const setCheckBox = (flag: any) => {
    let updated = listCheck(JSON.parse(JSON.stringify(userList)), flag);
    return updated;
  };

  const checkSelectedData = (data: any) => {
    return data.filter((ele: any) => {
      return ele.isSelected;
    });
  };

  const checked = (e: any, index: any) => {
    setMultiDelete(true);
    let data = JSON.parse(JSON.stringify(userList));

    if (e.target.checked) {
      data[index].isSelected = true;
    } else {
      data[index].isSelected = false;
    }

    setUserList(data);

    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);
  };

  const removeMultiDelete = () => {
    let unSelected = setCheckBox(false);
    setUserList(unSelected);
    const visibleName = checkSelectedData(unSelected);
    deleteShowModal(visibleName);
  };

  const actionMultiDelete = () => {
    deleteShowModals();
    const visibleName = checkSelectedData(userList);
    deleteShowModal(visibleName);
  };

  const allCheck = (e: any) => {
    setMultiDelete(true);
    let data = [];
    data = setCheckBox(e.target.checked);
    const visibleName = checkSelectedData(data);
    deleteShowModal(visibleName);

    setUserList(data);
  };

  const handleView = (row: any) => {
    setUserId(row._id);
    setShowDetail(true);
  };

  const handleEdit = (row: any) => {
    setUserId(row._id);
    setShowUserModal(true);
  };

  const handleShowModal = (row: any) => (
    deleteShowModals(true), deleteShowModal([row])
  );

  const tableAction = (
    handleView: any,
    handleEdit: any,
    handleShowModal: any
  ) => {
    return [
      {
        elementType: "button",
        text: BUTTON_LABEL.VIEW,
        className: `${`icon ${icons.ICON_VIEW}`} mx-1 `,
        icon: `icon ${icons.ICON_VIEW}`,
        action: handleView,
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.EDIT,
        className: `${`icon ${icons.ICON_EDIT}`} mx-1 `,
        // icon: "",
        action: handleEdit,
      },
      {
        elementType: "button",
        text: BUTTON_LABEL.DELETE,
        className: `${`icon ${icons.ICON_DELETE}`} mx-1 `,
        // icon: "",
        action: handleShowModal,
      },
    ];
  };

  const action = tableAction(handleView, handleEdit, handleShowModal);

  const deleteModal = (handleDelete: any, closeModal: any, isLoading: any) => {
    return [
      {
        text: BUTTON_LABEL.YES,
        action: handleDelete,
        className: "btn theme-btn theme-btn-sm w-auto",
        isLoading: isLoading,
      },
      {
        text: BUTTON_LABEL.NO,
        action: closeModal,
        className: "btn theme-btn theme-btn-sm btn-inverse w-auto",
      },
    ];
  };

  const handleDelete = async () => {
    const userDeleteResponse = await userDelete({ _id: deleteId })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (userDeleteResponse && userDeleteResponse?.status === true) {
      userDeleteFlow(userDeleteResponse);
      // deleteCloseModal();
      // deleteShowModal([]);
    }
  };

  const closeModal = () => {
    deleteCloseModal();
    deleteShowModal([]);
    removeMultiDelete();
  };

  const deleteModalButton = deleteModal(
    handleDelete,
    closeModal,
    userDeleteLoading
  );

  const handleResetPassword = () => {
    setResetPassword(!resetPassword);
  };

  const userImportFlow = (data: any) => {
    userRefetch();
    toastSuccess(data.message);
  };

  const userExportFlow = (data: any) => {
    let a = document.createElement("a");
    a.href = downloadPath + data.item;
    a.download = data.file;
    a.click();
    toastSuccess(data.message);
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const userImportResponse = await userImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (userImportResponse && userImportResponse?.status === true) {
      userImportFlow(userImportResponse);
    }
  };

  const handleCsvExport = async () => {
    const userExportResponse = await userExport("")
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (userExportResponse && userExportResponse?.status === true) {
      userExportFlow(userExportResponse);
    }
  };

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.USER_MANAGEMENT_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-4 d-flex align-items-center">
                  {/* <a
                    href="#"
                    className="me-2 btn theme-btn theme-btn-sm btn-inverse"
                  >
                    <i
                      className={`icon ${icons.ICON_FILTER} me-2 d-inline-block`}
                    ></i>
                    {BUTTON_LABEL.FILTER}
                  </a> */}
                  <SimpleButton
                    className={"me-2 btn theme-btn theme-btn-sm btn-inverse"}
                    btnIcon={icons.ICON_FILTER}
                    btnIconClass={"me-2 d-inline-block"}
                    text={BUTTON_LABEL.FILTER}
                  />
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />

                    <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleRef}
                      text={BUTTON_LABEL.IMPORT}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={iconInput}
                        onChange={handleCsvImport}
                        accept=".csv"
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                        hidden
                      />
                    </SimpleButton>
                    {/* <a
                      href="#"
                      className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      onClick={handleRef}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={iconInput}
                        onChange={handleCsvImport}
                        accept=".csv"
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                        hidden
                      />
                      {BUTTON_LABEL.IMPORT}
                    </a>

                    <a
                      href="#"
                      className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      onClick={handleCsvExport}
                    >
                      {BUTTON_LABEL.EXPORT}
                    </a> */}
                    {authProvider.show("store", "edit") ? (
                      // <a
                      //   href="#"
                      //   className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      //   onClick={handleUserModal}
                      // >
                      //   <i className={`${icons.ICON_ADD}`}></i>
                      //   {BUTTON_LABEL.ADD}
                      // </a>

                      <SimpleButton
                        className={
                          "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                        }
                        onClick={handleUserModal}
                        btnIcon={icons.ICON_ADD}
                        text={BUTTON_LABEL.ADD}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={userList}
                    columns={columns}
                    select={true}
                    action={action}
                    sort={sort}
                    setSort={setSort}
                    allCheck={allCheck}
                    allCheckedCheckbox={deleteId.length === userList.length}
                    checked={checked}
                    coloumnDrop={false}
                    count={count}
                    deleteId={deleteId}
                    removeMultiDelete={removeMultiDelete}
                    actionMultiDelete={actionMultiDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* <div className="modal-backdrop fade show"></div> */}
      </div>

      <CustomModal
        show={showModal}
        close={closeModal}
        message={`${""} ${deletedName} User?`}
        size="md"
        modalTitle={""}
        modalButton={deleteModalButton}
        children={undefined}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
        // modalTitleIcon={icons.ICON_DELETE_V2}
      />

      <CustomModal
        show={showUserModal}
        close={closeUserModal}
        size={"lg"}
        message={""}
        modalTitle={editMode ? LABELS.EDIT_USER_TEXT : LABELS.ADD_USER_TEXT}
        modalButton={addUserModalButton}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      >
        <AddUser
          userId={userId}
          editMode={editMode}
          setEditMode={setEditMode}
          addUserData={addUserData}
          setAddUserData={setAddUserData}
          resetPassword={resetPassword}
          setResetPassword={setResetPassword}
          handleOnChange={handleOnChange}
          handleResetPassword={handleResetPassword}
          errors={errors}
        />
      </CustomModal>

      <CustomModal
        show={showDetail}
        close={closeDetail}
        size={"lg"}
        message={""}
        modalTitle={LABELS.USER_MANAGEMENT_DETAILS_TEXT}
        modalButton={userDetailModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <UserDetail
          userDetailData={userDetailData}
          getUserLoading={getUserLoading}
        />
      </CustomModal>
    </>
  );
};

export default User;
