import { emptySplitApi } from "../emptySplit-api";

export const customerApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    customerList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: "/customers/list",
          method: "post",
          body: data,
        };
      },
    }),
    customerGet: builder.query({
      query: (id) => ({ url: `/customers/get/${id}`, method: "post" }),
    }),
    customerUpdate: builder.mutation<any, any>({
      query: ({ id, data }) => {
        return {
          url: `/customers/update/${id}`,
          method: "post",
          body: data,
        };
      },
    }),
    customerImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: "/customers/import",
          method: "post",
          body: data,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useCustomerListQuery,
  useCustomerGetQuery,
  useCustomerUpdateMutation,
  useCustomerImportMutation,
} = customerApi;
