import SimpleButton from "../../../shared/components/Button/Button";
import { BUTTON_LABEL, icons } from "../../../shared/constants";

const PopulateBar = (props: any) => {
  const { show, closePopulateBar, handlePrintOrder } = props;

  return (
    <>
      <div className={`populate-bar ${show ? "show" : ""}`}>
        <div
          className="populate-bar-header align-items-center d-flex"
          onClick={closePopulateBar}
        >
          <p className="cursor-pointer">
            <i className={icons.ICON_CHEVRON_LEFT}></i>
            <span>Populate</span>
          </p>
        </div>
        <div className="populate-bar-body custom-scrollbar">
          <div className="item-card">
            <div className="item-header ">
              <span>Jodhpuri</span>
              <span onClick={handlePrintOrder}>
                <i className={icons.ICON_PRINTER_FILLED}></i>
              </span>
            </div>
            <div className="item-body">
              <div className="populate-values">
                <div className="value">
                  <label htmlFor="">L</label>
                  <span className="label-box">31.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SH</label>
                  <span className="label-box">19.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SL</label>
                  <span className="label-box">26.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">CH</label>
                  <span className="label-box">48</span>
                </div>
                <div className="value">
                  <label htmlFor="">ST</label>
                  <span className="label-box">47</span>
                </div>
                <div className="value">
                  <label htmlFor="">H</label>
                  <span className="label-box">47.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">N</label>
                  <span className="label-box">16.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.B</label>
                  <span className="label-box">18</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.F</label>
                  <span className="label-box">17</span>
                </div>
                <div className="value">
                  <label htmlFor="">L</label>
                  <span className="label-box">31.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SH</label>
                  <span className="label-box">19.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SL</label>
                  <span className="label-box">26.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">CH</label>
                  <span className="label-box">48</span>
                </div>
                <div className="value">
                  <label htmlFor="">ST</label>
                  <span className="label-box">47</span>
                </div>
                <div className="value">
                  <label htmlFor="">H</label>
                  <span className="label-box">47.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">N</label>
                  <span className="label-box">16.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.B</label>
                  <span className="label-box">18</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.F</label>
                  <span className="label-box">17</span>
                </div>
              </div>
            </div>
          </div>
          <div className="item-card">
            <div className="item-header ">
              <span>Kurta/Shirt</span>
              <span onClick={handlePrintOrder}>
                <i className={icons.ICON_PRINTER_FILLED}></i>
              </span>
            </div>
            <div className="item-body">
              <div className="populate-values">
                <div className="value">
                  <label htmlFor="">L</label>
                  <span className="label-box">31.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SH</label>
                  <span className="label-box">19.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SL</label>
                  <span className="label-box">26.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">CH</label>
                  <span className="label-box">48</span>
                </div>
                <div className="value">
                  <label htmlFor="">ST</label>
                  <span className="label-box">47</span>
                </div>
                <div className="value">
                  <label htmlFor="">H</label>
                  <span className="label-box">47.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">N</label>
                  <span className="label-box">16.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.B</label>
                  <span className="label-box">18</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.F</label>
                  <span className="label-box">17</span>
                </div>
                <div className="value">
                  <label htmlFor="">L</label>
                  <span className="label-box">31.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SH</label>
                  <span className="label-box">19.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SL</label>
                  <span className="label-box">26.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">CH</label>
                  <span className="label-box">48</span>
                </div>
                <div className="value">
                  <label htmlFor="">ST</label>
                  <span className="label-box">47</span>
                </div>
                <div className="value">
                  <label htmlFor="">H</label>
                  <span className="label-box">47.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">N</label>
                  <span className="label-box">16.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.B</label>
                  <span className="label-box">18</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.F</label>
                  <span className="label-box">17</span>
                </div>
              </div>
            </div>
          </div>
          <div className="item-card">
            <div className="item-header ">
              <span>Kurta/Shirt</span>
              <span onClick={handlePrintOrder}>
                <i className={icons.ICON_PRINTER_FILLED}></i>
              </span>
            </div>
            <div className="item-body">
              <div className="populate-values">
                <div className="value">
                  <label htmlFor="">L</label>
                  <span className="label-box">31.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SH</label>
                  <span className="label-box">19.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SL</label>
                  <span className="label-box">26.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">CH</label>
                  <span className="label-box">48</span>
                </div>
                <div className="value">
                  <label htmlFor="">ST</label>
                  <span className="label-box">47</span>
                </div>
                <div className="value">
                  <label htmlFor="">H</label>
                  <span className="label-box">47.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">N</label>
                  <span className="label-box">16.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.B</label>
                  <span className="label-box">18</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.F</label>
                  <span className="label-box">17</span>
                </div>
                <div className="value">
                  <label htmlFor="">L</label>
                  <span className="label-box">31.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SH</label>
                  <span className="label-box">19.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">SL</label>
                  <span className="label-box">26.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">CH</label>
                  <span className="label-box">48</span>
                </div>
                <div className="value">
                  <label htmlFor="">ST</label>
                  <span className="label-box">47</span>
                </div>
                <div className="value">
                  <label htmlFor="">H</label>
                  <span className="label-box">47.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">N</label>
                  <span className="label-box">16.5</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.B</label>
                  <span className="label-box">18</span>
                </div>
                <div className="value">
                  <label htmlFor="">C.F</label>
                  <span className="label-box">17</span>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-checkbox">
            <input
              className="custom-control-input"
              type="checkbox"
              id="cat1"
              value="option1"
            />
            <label className="custom-control-label" htmlFor="cat1">
              Print all
            </label>
            <a
              href="javascript:void(0)"
              className="ms-2 d-inline-block"
              onClick={handlePrintOrder}
            >
              <i className={icons.ICON_PRINTER_FILLED}></i>
            </a>
          </div>
          <div className="text-center mt-5">
            {/* <a href="javascript:void(0)" className="btn theme-btn theme-btn-sm"> */}
            <SimpleButton
              className={"btn theme-btn theme-btn-sm"}
              // onClick={handleCsvExport}
              text={BUTTON_LABEL.CLOSE}
            />

            {/* </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopulateBar;
