import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// initialize an empty api service that we'll inject endpoints into as needed
export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL + "/api",
    // credentials: "include",
    // method: "POST",
    prepareHeaders: async (headers, { getState }: any) => {
      const token = localStorage.getItem("x-access-token");
      if (token) {
        headers.set("x-access-token", token);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});
