import Navbar from "../../../Navbar/NavbarComponent";
import { LABELS } from "../../../../shared/constants";

const ProductDetail = () => {
  return (
    <div className="full-height">
      <Navbar title={LABELS.PRODUCT_BOM_DETAILS_TEXT} />
      <main className="main-content">
        <div className="pt-2">
          <div className="container-fluid">
            <div className="card custom-card card-full mt-3 top-card">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-12 p-3 br-1 col-md-7">
                    Nilesh Mitesh NM-61125 FRS-115 40 100CMS L.PISTA
                  </div>
                  <div className="col-12  col-md-5">
                    <div className="row">
                      <div className="col-12 col-md-6 p-3 br-1">NMF773281</div>
                      <div className="col-12 col-md-6 p-3">C_EMB_S_F</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="card custom-card table-card card-full mt-3">
                  <div className="card-header">
                    <div className="card-title mb-0">Operation Details</div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive height-auto box-shadow-0">
                      <table className="table table-header-shadow mb-0">
                        <tr>
                          <th>Op. Seq.</th>
                          <th>Process Name</th>
                          <th>Sub Assembly Item</th>
                          <th>Assembly Supply</th>
                        </tr>
                        <tr>
                          <td>
                            <div className="proccess-status is-active">1</div>
                          </td>
                          <td>Cutting</td>
                          <td>Shrewani</td>
                          <td>None</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="proccess-status">2</div>
                          </td>
                          <td>Cutting</td>
                          <td>Shrewani</td>
                          <td>None</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="proccess-status">3</div>
                          </td>
                          <td>Cutting</td>
                          <td>Shrewani</td>
                          <td>None</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="proccess-status done">4</div>
                          </td>
                          <td>Cutting</td>
                          <td>Shrewani</td>
                          <td>None</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card custom-card table-card card-full mt-3">
                  <div className="card-header">
                    <div className="card-title mb-0">Version Details</div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive height-auto box-shadow-0 tbl-pad-normal">
                      <table className="table table-header-shadow mb-0">
                        <tr>
                          <th>Version</th>
                          <th>Is Approved</th>
                          <th>Created By</th>
                          <th>Created On</th>
                          <th>Approved By</th>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <div className="custom-control custom-checkbox d-inline-block p-0 bg-none">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="check_2"
                                checked
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check_2"
                              ></label>
                            </div>
                          </td>
                          <td>Sheetal Aareth...</td>
                          <td>24/04/2024</td>
                          <td>Sheetal Aareth...</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <div className="custom-control custom-checkbox d-inline-block p-0 bg-none">
                              <input
                                type="checkbox"
                                className="custom-control-input disabled"
                                id="check_2"
                                checked
                                readOnly
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check_2"
                              ></label>
                            </div>
                          </td>
                          <td>Sheetal Aareth...</td>
                          <td>24/04/2024</td>
                          <td>Sheetal Aareth...</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <div className="custom-control custom-checkbox d-inline-block p-0 bg-none">
                              <input
                                type="checkbox"
                                className="custom-control-input disabled"
                                id="check_2"
                                checked
                                readOnly
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check_2"
                              ></label>
                            </div>
                          </td>
                          <td>Sheetal Aareth...</td>
                          <td>24/04/2024</td>
                          <td>Sheetal Aareth...</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card custom-card table-card card-full mt-3 version-details">
                  <div className="card-header">
                    <div className="card-title mb-0">Component Item</div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive height-auto box-shadow-0 ">
                      <table className="table table-header-shadow mb-0">
                        <thead>
                          <tr>
                            <th>Component Item</th>
                            <th>Barcode</th>
                            <th>Sub Assembly Item</th>
                            <th>Quantity</th>
                            <th>Supply Type</th>
                            <th>Shrinkage %</th>
                            <th>Tolerance%</th>
                            <th>Mandatory</th>
                            <th>Alt. Item Group</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Nilesh Mitech..</td>
                            <td>NMF773281</td>
                            <td>CHUDIDAR</td>
                            <td>1.0000000</td>
                            <td>Pull on Order</td>
                            <td></td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Nilesh Mitech..</td>
                            <td>NMF773281</td>
                            <td>KURTA</td>
                            <td>1.0000000</td>
                            <td>Pull on Order</td>
                            <td></td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Nilesh Mitech..</td>
                            <td>NMF773281</td>
                            <td>DUPATTA</td>
                            <td>1.0000000</td>
                            <td>Pull on Order</td>
                            <td></td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Nilesh Mitech..</td>
                            <td>NMF773281</td>
                            <td>SHEEWANI</td>
                            <td>1.0000000</td>
                            <td>Pull on Order</td>
                            <td></td>
                            <td></td>
                            <td>Yes</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProductDetail;
