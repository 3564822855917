import React, { useState } from "react";
import {
  flexRender,
  useReactTable,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Dropdown } from "react-bootstrap";
import Pagination from "../Pagination";
import CheckBox from "../CheckBox/CheckBox";
import MultiDelete from "../multiDelete";
import { icons } from "../../constants";
import { ColoumnGrid } from "../../../App/assets/img";

const Table = (props: any) => {
  const {
    data,
    columns,
    select,
    action,
    sort,
    setSort,
    allCheck,
    allCheckedCheckbox,
    checked,
    coloumnDrop,
    count,
    deleteId,
    removeMultiDelete,
    actionMultiDelete,
    showIconMenu,
    iconMenu,
    handleRow,
    isHandleRowEnabled,
  } = props;

  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [column, setColumn] = useState(columns);

  const table = useReactTable({
    data,
    columns: column,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const actionType = (element: any, row: any) => {
    if (element.elementType === "button") {
      return (
        <Dropdown.Item onClick={() => element.action && element.action(row)}>
          <i className={element.className} />
          <span>{element.text}</span>
        </Dropdown.Item>
      );
    }
  };

  const handleColoumnChange = (header: any) => {
    let updated = column.map((el: any) => {
      if (header === el.header) {
        return { ...el, isShow: el.isShow === true ? false : true };
      } else {
        return el;
      }
    });

    setColumn(updated);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            {select ? (
              <MultiDelete
                multiDelete={deleteId.length}
                removeMultiDelete={removeMultiDelete}
                totalLength={data.length}
                handleOnClick={actionMultiDelete}
                count={count}
              />
            ) : (
              ""
            )}
            {/* If checkbox then add this class here - table table-header-shadow cell-1-small mb-0
             */}
            <table
              className={`table table-header-shadow ${
                count > 0 && data.length > 0 && select ? "cell-1-small" : ""
              } mb-0`}
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {select && data.length > 0 ? (
                      <th>
                        <div>
                          <label className="mb-0 control control-solid control-solid-primary control--checkbox select-all">
                            <CheckBox
                              onChange={(e) => allCheck(e, data)}
                              value={allCheckedCheckbox}
                              label={""}
                              name={""}
                              id={headerGroup.id}
                            />
                            <span className="control__indicator"></span>
                          </label>
                        </div>
                      </th>
                    ) : (
                      ""
                    )}
                    {headerGroup.headers.map((header: any) =>
                      header.column.columnDef.isShow ? (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ) : (
                        ""
                      )
                    )}
                    {action && (
                      <th className="text-center">
                        <div>Actions</div>
                      </th>
                    )}
                    {coloumnDrop && (
                      <th>
                        <div className="dropdown cell-dropdown"></div>
                        <Dropdown className="dropdown cell-dropdown float-end">
                          <Dropdown.Toggle className="no-btn">
                            <a href="#">
                              <img src={ColoumnGrid} />
                            </a>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu has-checkbox">
                            {headerGroup.headers.map((header: any) => {
                              return (
                                <li>
                                  <div className="dropdown-item">
                                    <CheckBox
                                      label={
                                        header.isPlaceholder
                                          ? null
                                          : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )
                                      }
                                      value={
                                        header.column.columnDef.isShow
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        handleColoumnChange(
                                          header.column.columnDef.header
                                        )
                                      }
                                      name={
                                        header.isPlaceholder
                                          ? null
                                          : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                            )
                                      }
                                      id={header.id}
                                    />
                                  </div>
                                </li>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </th>
                    )}
                  </tr>
                ))}
              </thead>
              <tbody>
                <>
                  {count > 0 && data.length > 0 ? (
                    table.getRowModel().rows.map((row: any) => {
                      return (
                        <>
                          <tr key={row.id}>
                            {select ? (
                              <td className="">
                                <label className="mb-0 control control-solid control-solid-primary control--checkbox select-all">
                                  <CheckBox
                                    onChange={(e) => checked(e, row.index)}
                                    value={row.original.isSelected}
                                    label={""}
                                    name={""}
                                    id={`user_${row.index}`}
                                  />
                                  <span className="control__indicator"></span>
                                </label>
                              </td>
                            ) : (
                              ""
                            )}
                            {row.getVisibleCells().map((cell: any) =>
                              cell.column.columnDef.isShow ? (
                                <td
                                  key={cell.id}
                                  onClick={(e) =>
                                    isHandleRowEnabled
                                      ? handleRow(e, row.original)
                                      : undefined
                                  }
                                  className="cursor-pointer"
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              ) : (
                                ""
                              )
                            )}
                            {action && (
                              <td>
                                <div
                                  className={`d-flex justify-content-center action-btns`}
                                >
                                  {action && (
                                    <Dropdown className="custom-dropdown d-flex align-items-center">
                                      <Dropdown.Toggle className="no-btn caret-hide">
                                        <div className="chevron-circle">
                                          <i
                                            className={icons.ICON_CHEVRON_DOWN}
                                          ></i>
                                        </div>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {action.map((ac: any, index: any) => {
                                          return actionType(ac, row.original);
                                        })}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )}
                                </div>
                              </td>
                            )}
                            {coloumnDrop && (
                              <td>
                                {showIconMenu ? (
                                  <Dropdown className="custom-dropdown d-flex align-items-center">
                                    <Dropdown.Toggle className="no-btn caret-hide">
                                      <div className="chevron-circle">
                                        <i
                                          className={icons.ICON_CHEVRON_DOWN}
                                        ></i>
                                      </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {iconMenu.map((ele: any, index: any) => {
                                        return (
                                          <Dropdown.Item
                                            key={index}
                                            onClick={ele.action}
                                          >
                                            <i className={ele.icon} />
                                            <span>{ele.name}</span>
                                          </Dropdown.Item>
                                        );
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  <span>&nbsp;</span> // Maintains spacing when dropdown is not shown
                                )}
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={columns.length + 1}>
                        <strong>No Data Found</strong>
                      </td>
                    </tr>
                  )}
                </>
              </tbody>
              {/* <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot> */}
            </table>
          </div>
        </div>
      </div>
      <div className="row align-items-center pagination">
        <Pagination count={count} sort={sort} setSort={setSort} />
      </div>
    </>
  );
};

export default Table;
