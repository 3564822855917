import { ERROR_MESSAGE } from "../constants";

export const loginValidation = (props: any): any => {
  let formErrors: any = {};
  let formValid: any = true;

  const { username, password } = props;

  if (!username) {
    formErrors.username = ERROR_MESSAGE.USER_NAME_REQUIRE;
    formValid = false;
  }

  if (!password) {
    formValid = false;
    formErrors.password = ERROR_MESSAGE.PASSWORD_REQUIRE;
  }

  return { formValid, formErrors };
};
