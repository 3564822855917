import { useEffect, useRef, useState } from "react";
import Navbar from "../../Navbar/NavbarComponent";
import TextBox from "../../../shared/components/TextBox";
import {
  useChangePasswordMutation,
  useLogoUploadMutation,
  useUserProfileGetQuery,
  useUserProfileUpdateMutation,
} from "../../../Features/user-profile/user-profile-slice";
import Button from "react-bootstrap/Button";
import Password from "../../../shared/components/Password";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  TOAST_MESSAGE,
  toastError,
  toastSuccess,
} from "../../../shared/constants";
import { UserImage } from "../../../App/assets/img";

const UserProfile = (props: any) => {
  const {} = props;
  const iconInput = useRef<any>(null);
  const [errors, setErrors] = useState({});
  const [userProfileData, setUserProfileData] = useState<any>({
    name: "",
    email: "",
    phone: "",
    logo: undefined,
  });
  const [userProfileCopy, setUserProfileCopy] = useState<any>({});
  const [passwordData, setPasswordData] = useState<any>({
    password: "",
    newPassword: "",
  });
  const [profileName, setProfileName] = useState();
  const [editMode, setEditMode] = useState(false);
  // const [editPassword, setEditPassword] = useState(false);
  const IMAGE_URL = process.env.REACT_APP_BACKEND_URL + "/uploads/";

  let defaultImage =
    userProfileData.logo !== undefined
      ? IMAGE_URL + userProfileData.logo
      : UserImage;

  const [changePassword, { isSuccess: changePasswordSuccess }] =
    useChangePasswordMutation();

  const [userProfileUpdate, { isSuccess: userProfileUpdateSuccess }] =
    useUserProfileUpdateMutation();

  const [
    logoUpload,
    { isSuccess: logoUploadSuccess, isLoading: logoUploadLoading },
  ] = useLogoUploadMutation();

  const {
    data: getUserProfileData,
    isLoading: getUserProfileLoading,
    isSuccess: getUserProfileSuccess,
  } = useUserProfileGetQuery("");

  useEffect(() => {
    if (getUserProfileSuccess && getUserProfileData) {
      setUserProfileData(getUserProfileData.item);
      setUserProfileCopy(getUserProfileData.item);
      setProfileName(getUserProfileData.item.name);
    }
  }, [getUserProfileSuccess, getUserProfileData]);

  const handleDataChange = (e: any) => (
    setUserProfileData({ ...userProfileData, [e.target.name]: e.target.value }),
    setErrors({ ...errors, [e.target.name]: "" })
  );

  const handleOnChange = (e: any) => (
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value }),
    setErrors({ ...errors, [e.target.name]: "" })
  );

  const logoUploadFlow = (data: any) => {
    toastSuccess(data.message);
    let d = data.fileName;
    setUserProfileData({ ...userProfileData, logo: d });
  };

  const handleLogoUpload = async (e: any) => {
    let formData = new FormData();
    formData.append("logo", e.target.files[0], e.target.files[0].name);
    const logoUploadResponse = await logoUpload(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (logoUploadResponse && logoUploadResponse?.status === true) {
      logoUploadFlow(logoUploadResponse);
    }
    // if (Object.keys(errors).length > 0 && errors.hasOwnProperty("logo")) {
    //   setErrors({ ...errors, logo: undefined });
    // }
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const profileUpdateFlow = (data: any) => {
    toastSuccess(data.message);
    setEditMode(false);
    setUserProfileData({});
  };

  const changePasswordFlow = (data: any) => {
    toastSuccess(data.message);
    // setEditPassword(false);
    setPasswordData({ ...passwordData, password: "", newPassword: "" });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleUpdateProfile = async () => {
    const profileUpdateResponse = await userProfileUpdate(userProfileData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (profileUpdateResponse && profileUpdateResponse?.status === true) {
      profileUpdateFlow(profileUpdateResponse);
    }
  };

  const handleCancelProfile = () => {
    setEditMode(false);
    setUserProfileData(userProfileCopy);
  };

  const handleChangePassword = async () => {
    const changePasswordResponse = await changePassword(passwordData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (changePasswordResponse && changePasswordResponse?.status === true) {
      changePasswordFlow(changePasswordResponse);
    }
    // setEditPassword(false);
  };

  return (
    <div className="full-height">
      <Navbar title={LABELS.USER_PROFILE_TEXT} />
      <main className="main-content profile-page">
        <div className="pt-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card border-0">
                  <div className="card-body">
                    <h6>{LABELS.MY_PROFILE_TEXT}</h6>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="card">
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-md-3">
                                <div className="img">
                                  <img
                                    src={defaultImage}
                                    alt=""
                                    className="img-fluid"
                                  />
                                  <div onClick={handleRef}>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      ref={iconInput}
                                      onChange={handleLogoUpload}
                                      accept="image/*"
                                      onClick={(e: any) => {
                                        e.target.value = null;
                                      }}
                                      hidden
                                    />
                                    <label htmlFor="file">
                                      <i
                                        className={`icon ${icons.ICON_PENCIL_CIRCLE} change-photo`}
                                      ></i>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-9">
                                <h6 className="name mb-2 text-capitalize">
                                  {profileName}
                                </h6>
                                {/* <p className="mb-0"> NA</p>
                                <p className="mb-0">Mumbai</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="my-3">{LABELS.PERSONAL_INFORMATION_TEXT}</h6>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="card has-input">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <TextBox
                                    name={ACTION_LABEL.NAME}
                                    placeholder={LABELS.FULLNAME_TEXT}
                                    id={LABELS.FULLNAME_TEXT}
                                    label={LABELS.FULLNAME_TEXT}
                                    onChange={handleDataChange}
                                    value={userProfileData.name}
                                    disable={editMode ? "" : true}
                                  />
                                </div>
                                {/* <label
                                  htmlFor=""QA
                                  className="text-muted d-block"
                                >
                                  First Name
                                </label>
                                <p>Mistry</p> */}
                              </div>
                              {/* <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <TextBox
                                    name={ACTION_LABEL.LASTNAME}
                                    placeholder={LABELS.LASTNAME_TEXT}
                                    id={LABELS.LASTNAME_TEXT}
                                    label={LABELS.LASTNAME_TEXT}
                                    onChange={handleDataChange}
                                    value={userProfileData.lastName}
                                    disable={editMode ? "" : true}
                                  />
                                </div>
                              </div> */}
                              {editMode ? (
                                ""
                              ) : (
                                <div className="col-12 col-md-8 text-end">
                                  <button
                                    className="btn btn-default btn-light"
                                    onClick={handleEdit}
                                  >
                                    {BUTTON_LABEL.EDIT}
                                    <i
                                      className={`icon ${icons.ICON_EDIT}`}
                                    ></i>
                                  </button>
                                </div>
                              )}
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <TextBox
                                    name={ACTION_LABEL.EMAIL}
                                    placeholder={LABELS.EMAIL_ADDRESS_TEXT}
                                    id={LABELS.EMAIL_ADDRESS_TEXT}
                                    label={LABELS.EMAIL_ADDRESS_TEXT}
                                    onChange={handleDataChange}
                                    value={userProfileData.email}
                                    disable={editMode ? "" : true}
                                  />
                                </div>
                                {/* <label
                                  htmlFor=""
                                  className="text-muted d-block"
                                >
                                  Email Address
                                </label>
                                <p>hemnashum@gmail.com</p> */}
                              </div>
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <TextBox
                                    name={ACTION_LABEL.PHONE}
                                    placeholder={LABELS.PHONE_TEXT}
                                    id={LABELS.PHONE_TEXT}
                                    label={LABELS.PHONE_TEXT}
                                    onChange={handleDataChange}
                                    value={userProfileData.phone}
                                    disable={editMode ? "" : true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 text-end"></div>
                            </div>
                            {/* <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <TextBox
                                    name={ACTION_LABEL.DESIGNATION}
                                    placeholder={LABELS.DESIGNATION_TEXT}
                                    id={LABELS.DESIGNATION_TEXT}
                                    label={LABELS.DESIGNATION_TEXT}
                                    onChange={handleDataChange}
                                    value={userProfileData.designation}
                                    disable={editMode ? "" : true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <TextBox
                                    name={ACTION_LABEL.ADDRESS}
                                    placeholder={LABELS.ADDRESS_TEXT}
                                    id={LABELS.ADDRESS_TEXT}
                                    label={LABELS.ADDRESS_TEXT}
                                    onChange={handleDataChange}
                                    value={userProfileData.address}
                                    disable={editMode ? "" : true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 text-end"></div>
                            </div> */}
                            {/* <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <TextBox
                                    name={ACTION_LABEL.STATE}
                                    placeholder={LABELS.STATE_TEXT}
                                    id={LABELS.STATE_TEXT}
                                    label={LABELS.STATE_TEXT}
                                    onChange={handleDataChange}
                                    value={userProfileData.state}
                                    disable={editMode ? "" : true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <TextBox
                                    name={ACTION_LABEL.CITY}
                                    placeholder={LABELS.CITY_TEXT}
                                    id={LABELS.CITY_TEXT}
                                    label={LABELS.CITY_TEXT}
                                    onChange={handleDataChange}
                                    value={userProfileData.city}
                                    disable={editMode ? "" : true}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4 text-end"></div>
                            </div> */}
                            {editMode ? (
                              <div className="row mt-3">
                                <div className="col-12">
                                  <Button
                                    variant="btn theme-btn theme-btn-sm me-2"
                                    onClick={handleUpdateProfile}
                                  >
                                    {BUTTON_LABEL.SAVE}
                                  </Button>
                                  <Button
                                    variant="btn theme-btn theme-btn-sm btn-inverse"
                                    onClick={handleCancelProfile}
                                  >
                                    {BUTTON_LABEL.CANCEL}
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="my-3">{LABELS.CHANGE_PASSWORD_TEXT}</h6>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="card has-input">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <Password
                                    placeholder={LABELS.CURRENT_PASSWORD_TEXT}
                                    label={LABELS.CURRENT_PASSWORD_TEXT}
                                    value={passwordData.password}
                                    name={ACTION_LABEL.PASSWORD}
                                    onChange={handleOnChange}
                                    // disable={editPassword ? "" : true}
                                    // errorMessage={errors.password ? errors.password : null}
                                  />
                                </div>
                              </div>

                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <Password
                                    placeholder={LABELS.NEW_PASSWORD_TEXT}
                                    label={LABELS.NEW_PASSWORD_TEXT}
                                    value={passwordData.newPassword}
                                    name={ACTION_LABEL.NEW_PASSWORD}
                                    onChange={handleOnChange}
                                    // disable={editPassword ? "" : true}
                                    // errorMessage={errors.password ? errors.password : null}
                                  />
                                </div>
                                {/* <label
                                  htmlFor=""
                                  className="text-muted d-block"
                                >
                                  New Password
                                </label>
                                <p>**********</p> */}
                              </div>

                              {/* <label
                                  htmlFor=""
                                  className="text-muted d-block"
                                >
                                  Current Password
                                </label>
                                <p>**********</p> */}
                              {/* {editPassword ? (
                                ""
                              ) : (
                                <div className="col-12 col-md-4 text-end ms-auto">
                                  <button
                                    className="btn btn-default btn-light"
                                    onClick={handleEditPassword}
                                  >
                                    Change Password{" "}
                                    <i className={`icon ${icons.ICON_EDIT}`}></i>
                                  </button>
                                </div>
                              )} */}
                            </div>
                            {/* {editPassword ? ( */}
                            <div className="row mt-3">
                              <div className="col-12">
                                <Button
                                  variant="btn theme-btn theme-btn-sm me-2"
                                  onClick={handleChangePassword}
                                >
                                  {BUTTON_LABEL.CHANGE_PASSWORD}
                                </Button>
                                {/* <Button
                                  variant="btn theme-btn theme-btn-sm btn-inverse"
                                  onClick={handleCancelPassword}
                                >
                                  Cancel
                                </Button> */}
                              </div>
                            </div>
                            {/* ) : (
                              ""
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserProfile;
