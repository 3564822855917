import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../Auth/Login";
import DefaultLayout from "../layouts/DefaultLayout";
import ProtectedLayout from "../layouts/ProtectedLayout";
import Dashboard from "../BackOffice/Pages/Dashboard";
import StoreList from "../BackOffice/Pages/Store";
import SetOtp from "../Auth/SetOtp";
import SetPassword from "../Auth/SetPassword";
import Inventory from "../BackOffice/Pages/Inventory";
import User from "../BackOffice/Pages/User";
import StoreCoordinator from "../BackOffice/Pages/StoreCoordinator";
import Role from "../BackOffice/Pages/Role";
import Product from "../BackOffice/Pages/Product";
import Vendor from "../BackOffice/Pages/Vendor";
import Customer from "../BackOffice/Pages/Customer";
import ProductDetail from "../BackOffice/Pages/Product/ProductDetail";
import { AuthProvider } from "../context/AuthContext";
import UserProfile from "../BackOffice/Pages/UserProfile";
import ForgotPassword from "../Auth/ForgotPassword";
import OrderList from "../BackOffice/Pages/Order/OrderList";
import OrderDetail from "../BackOffice/Pages/Order/OrderDetail";
import ReportList from "../BackOffice/Pages/Report";
import AddRole from "../BackOffice/Pages/Role/AddRole";

const App = () => {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/resetpassword" element={<ForgotPassword />} />
            <Route path="/setotp" element={<SetOtp />} />
            <Route path="/setpassword" element={<SetPassword />} />
            {/* <Route path="/user-profile" element={<UserProfile />} /> */}
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {/*  <Route path="/store-management" element={<Store />} />
          <Route path="/store-management/add" element={<AddStore />} /> */}
          </Route>
          <Route element={<ProtectedLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/product-management" element={<Product />} />
            <Route path="/product-management/add" element={<ProductDetail />} />
            <Route path="/store-management" element={<StoreList />} />
            <Route path="/inventory-management" element={<Inventory />} />
            {/* <Route path="/user-managment" element={<User />} /> */}
            <Route path="/vendor-management" element={<Vendor />} />
            <Route path="/user-management/users" element={<User />} />
            <Route path="/customer-management" element={<Customer />} />
            <Route path="/store-coordinator" element={<StoreCoordinator />} />
            <Route path="/user-management/roles" element={<Role />} />
            <Route path="/user-management/roles/create" element={<AddRole />} />
            <Route path="/reports" element={<ReportList />} />
            <Route path="/order-management" element={<OrderList />} />
            <Route path="/order-details/:id" element={<OrderDetail />} />
            <Route path="/user-profile" element={<UserProfile />} />
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
