import { ERROR_MESSAGE, REGULAR_EX } from "../constants";

export const customerValidation = (props: any): any => {
  let formErrors: any = {};
  let formValid: any = true;

  const {
    salutation,
    firstName,
    middleName,
    lastName,
    address1,
    address2,
    city,
    state,
    district,
    pin,
    email,
    mobile,
    // mobileNo,
    remarks,
    gstStateName,
    gstin,
    isdCode,
    gender,
    dob,
    receiveMessage,
    prefCommuniCationMode,
    // store,
  } = props;

  // if (!salutation) {
  //   formValid = false;
  //   formErrors["salutation"] = ERROR_MESSAGE.SALUTATION_REQUIRE;
  // }

  if (!firstName) {
    formValid = false;
    formErrors["firstName"] = ERROR_MESSAGE.FIRST_NAME_REQUIRE;
  } else {
    if (firstName.match(REGULAR_EX.NAME_REGEX) === false) {
      formValid = false;
      formErrors["firstName"] = ERROR_MESSAGE.FULLNAME_REGEX_MESSAGE;
    }
  }

  if (!middleName) {
    formValid = false;
    formErrors["middleName"] = ERROR_MESSAGE.MIDDLE_NAME_REQUIRE;
  } else {
    if (middleName.match(REGULAR_EX.NAME_REGEX) === false) {
      formValid = false;
      formErrors["middleName"] = ERROR_MESSAGE.FULLNAME_REGEX_MESSAGE;
    }
  }

  if (!lastName) {
    formValid = false;
    formErrors["lastName"] = ERROR_MESSAGE.LAST_NAME_REQUIRE;
  } else {
    if (lastName.match(REGULAR_EX.NAME_REGEX) === false) {
      formValid = false;
      formErrors["lastName"] = ERROR_MESSAGE.FULLNAME_REGEX_MESSAGE;
    }
  }

  // if (!address1) {
  //   formValid = false;
  //   formErrors["address1"] = ERROR_MESSAGE.ADDRESS_1_REQUIRED;
  // }

  // if (!address2) {
  //   formValid = false;
  //   formErrors["address2"] = ERROR_MESSAGE.ADDRESS_2_REQUIRED;
  // }

  // if (!city) {
  //   formValid = false;
  //   formErrors["city"] = ERROR_MESSAGE.CITY_REQUIRED;
  // }

  // if (!state) {
  //   formValid = false;
  //   formErrors["state"] = ERROR_MESSAGE.STATE_REQUIRED;
  // }

  // if (!district) {
  //   formValid = false;
  //   formErrors["district"] = ERROR_MESSAGE.DISTRICT_REQUIRED;
  // }

  // if (!pin) {
  //   formValid = false;
  //   formErrors["pin"] = ERROR_MESSAGE.PIN_REQUIRED;
  // }

  // if (!email) {
  //   formValid = false;
  //   formErrors["email"] = ERROR_MESSAGE.EMAIL_REQUIRE;
  // } else {
  //   if (email.match(REGULAR_EX.EMAIL_REGEX) === false) {
  //     formValid = false;
  //     formErrors["email"] = ERROR_MESSAGE.EMAIL_REGEX_MESSAGE;
  //   }
  // }

  if (!mobile) {
    formValid = false;
    formErrors["mobile"] = ERROR_MESSAGE.MOBILE_REQUIRE;
  } else {
    if (mobile.match(REGULAR_EX.PHONE_REGEX) === false) {
      formValid = false;
      formErrors["mobile"] = ERROR_MESSAGE.MOBILE_NO_REGEX_MESSAGE;
    }
  }

  // if (!remarks) {
  //   formValid = false;
  //   formErrors["remarks"] = ERROR_MESSAGE.REMARKS_REQUIRED;
  // }

  // if (!gstStateName) {
  //   formValid = false;
  //   formErrors["gstStateName"] = ERROR_MESSAGE.GST_STATE_NAME_REQUIRED;
  // }

  // if (!gstin) {
  //   formValid = false;
  //   formErrors["gstin"] = ERROR_MESSAGE.GST_IN_REQUIRED;
  // }

  if (!isdCode) {
    formValid = false;
    formErrors["isdCode"] = ERROR_MESSAGE.ISD_CODE_REQUIRED;
  }

  if (!gender) {
    formValid = false;
    formErrors["gender"] = ERROR_MESSAGE.GENDER_REQUIRED;
  }

  if (!dob) {
    formValid = false;
    formErrors["dob"] = ERROR_MESSAGE.DOB_REQUIRED;
  }

  if (!receiveMessage) {
    formValid = false;
    formErrors["receiveMessage"] = ERROR_MESSAGE.RECEIVED_MESSAGE_REQUIRED;
  }

  if (!prefCommuniCationMode) {
    formValid = false;
    formErrors["prefCommuniCationMode"] =
      ERROR_MESSAGE.PREF_COMMUNICATION_MODE_REQUIRED;
  }

  return { formValid, formErrors };
};
