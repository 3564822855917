import React from "react";
import TextBox from "../../../../shared/components/TextBox";
import { ACTION_LABEL, LABELS } from "../../../../shared/constants";

const VendorDetail = (props: any) => {
  const { vendorDetailData, getVendorLoading } = props;
  return (
    <>
      {!getVendorLoading && vendorDetailData !== undefined ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.JOBBER_ID_TEXT}
                  placeholder={LABELS.JOBBER_ID_TEXT}
                  name={ACTION_LABEL.JOBBER_ID}
                  value={vendorDetailData.jobberId}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.NAME_TEXT}
                  placeholder={LABELS.NAME_TEXT}
                  name={ACTION_LABEL.NAME}
                  value={vendorDetailData.name}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.ALIAS_TEXT}
                  placeholder={LABELS.ALIAS_TEXT}
                  name={ACTION_LABEL.ALIAS}
                  value={vendorDetailData.alias}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CODE_TEXT}
                  placeholder={LABELS.CODE_TEXT}
                  name={ACTION_LABEL.CODE}
                  value={vendorDetailData.code}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.IDENTITY_NO_TEXT}
                  placeholder={LABELS.IDENTITY_NO_TEXT}
                  name={ACTION_LABEL.IDENTITY_NO}
                  value={vendorDetailData.identityNo}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.BILLING_CONTACT_PERSON_TEXT}
                  placeholder={LABELS.BILLING_CONTACT_PERSON_TEXT}
                  name={ACTION_LABEL.BILLING_CONTACT_PERSON}
                  value={vendorDetailData.billingContactPerson}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CLASS_NAME_TEXT}
                  placeholder={LABELS.CLASS_NAME_TEXT}
                  name={ACTION_LABEL.CLASS_NAME}
                  value={vendorDetailData.className}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CLASS_TYPE_TEXT}
                  placeholder={LABELS.CLASS_TYPE_TEXT}
                  name={ACTION_LABEL.CLASS_TYPE}
                  value={vendorDetailData.classType}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.AR_LEDGER_TEXT}
                  placeholder={LABELS.AR_LEDGER_TEXT}
                  name={ACTION_LABEL.AR_LEDGER}
                  value={vendorDetailData.arLedger}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CONTACT_PERSON_NAME_TEXT}
                  placeholder={LABELS.CONTACT_PERSON_NAME_TEXT}
                  name={ACTION_LABEL.CONTACT_PERSON_NAME}
                  value={vendorDetailData.contactPersonName}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.ADDRESS_TEXT}
                  placeholder={LABELS.ADDRESS_TEXT}
                  name={ACTION_LABEL.ADDRESS}
                  value={vendorDetailData.address}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.CITY_TEXT}
                  placeholder={LABELS.CITY_TEXT}
                  name={ACTION_LABEL.CITY}
                  value={vendorDetailData.city}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.STATE_TEXT}
                  placeholder={LABELS.STATE_TEXT}
                  name={ACTION_LABEL.STATE}
                  value={vendorDetailData.state}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.PIN_TEXT}
                  placeholder={LABELS.PIN_TEXT}
                  name={ACTION_LABEL.PIN}
                  value={vendorDetailData.pin}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.TAX_REGION_TEXT}
                  placeholder={LABELS.TAX_REGION_TEXT}
                  name={ACTION_LABEL.TAX_REGION}
                  value={vendorDetailData.taxRegion}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.PHONE_NO_TEXT}
                  placeholder={LABELS.PHONE_NO_TEXT}
                  name={ACTION_LABEL.PHONE_NO}
                  value={vendorDetailData.phoneNo}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.EMAIL_TEXT}
                  placeholder={LABELS.EMAIL_TEXT}
                  name={ACTION_LABEL.EMAIL}
                  value={vendorDetailData.email}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.TRADE_GROUP_TEXT}
                  placeholder={LABELS.TRADE_GROUP_TEXT}
                  name={ACTION_LABEL.TRADE_GROUP}
                  value={vendorDetailData.tradeGroup}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.PURCHASE_TERM_TEXT}
                  placeholder={LABELS.PURCHASE_TERM_TEXT}
                  name={ACTION_LABEL.PURCHASE_TERM}
                  value={vendorDetailData.purchaseTerm}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.PAN_NO_TEXT}
                  placeholder={LABELS.PAN_NO_TEXT}
                  name={ACTION_LABEL.PAN_NO}
                  value={vendorDetailData.panNo}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.GST_CATEGORY_TEXT}
                  placeholder={LABELS.GST_CATEGORY_TEXT}
                  name={ACTION_LABEL.GST_CATEGORY}
                  value={vendorDetailData.gstCategory}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.GSTIN_NO_TEXT}
                  placeholder={LABELS.GSTIN_NO_TEXT}
                  name={ACTION_LABEL.GST_IN_NO}
                  value={vendorDetailData.gstInNo}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center mb-2">
                <TextBox
                  label={LABELS.GST_STATE_TEXT}
                  placeholder={LABELS.GST_STATE_TEXT}
                  name={ACTION_LABEL.GST_STATE}
                  value={vendorDetailData.gstState}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default VendorDetail;
