import { ERROR_MESSAGE, REGULAR_EX } from "../constants";

export const storeCoordinatorValidation = (props: any, resetKey: any): any => {
  let formErrors: any = {};
  let formValid: any = true;

  const {
    name,
    email,
    phone,
    designation,
    address,
    state,
    city,
    password,
    // role,
    store,
  } = props;
  if (!name) {
    formValid = false;
    formErrors["name"] = ERROR_MESSAGE.FULL_NAME_REQUIRE;
  } else {
    if (name.match(REGULAR_EX.NAME_REGEX) === false) {
      formValid = false;
      formErrors["name"] = ERROR_MESSAGE.FULLNAME_REGEX_MESSAGE;
    }
  }

  // if (!lastName) {
  //   formValid = false;
  //   formErrors["lastName"] = ERROR_MESSAGE.FULL_NAME_REQUIRE;
  // } else {
  //   if (lastName.match(REGULAR_EX.NAME_REGEX) === false) {
  //     formValid = false;
  //     formErrors["lastName"] = ERROR_MESSAGE.FULLNAME_REGEX_MESSAGE;
  //   }
  // }

  if (!email) {
    formValid = false;
    formErrors["email"] = ERROR_MESSAGE.EMAIL_REQUIRE;
  } else {
    if (email.match(REGULAR_EX.EMAIL_REGEX) === false) {
      formValid = false;
      formErrors["email"] = ERROR_MESSAGE.EMAIL_REGEX_MESSAGE;
    }
  }

  if (resetKey) {
    if (!password) {
      formValid = false;
      formErrors["password"] = ERROR_MESSAGE.PASSWORD_REQUIRE;
    }
  }

  if (!phone) {
    formValid = false;
    formErrors["phone"] = ERROR_MESSAGE.PHONE_REQUIRE;
  } else {
    if (phone.match(REGULAR_EX.PHONE_REGEX) === false) {
      formValid = false;
      formErrors["phone"] = ERROR_MESSAGE.PHONE_REGEX_MESSAGE;
    }
  }

  if (!store) {
    formValid = false;
    formErrors["store"] = ERROR_MESSAGE.STORE_REQUIRE;
  }

  //   if (!role) {
  //     formValid = false;
  //     formErrors["role"] = ERROR_MESSAGE.ROLE_REQUIRE;
  //   }

  if (!address) {
    formValid = false;
    formErrors["address"] = ERROR_MESSAGE.ADDRESS_REQUIRED;
  }

  if (!designation) {
    formValid = false;
    formErrors["designation"] = ERROR_MESSAGE.DESIGNATION_REQUIRED;
  }

  if (!state) {
    formValid = false;
    formErrors["state"] = ERROR_MESSAGE.STATE_REQUIRED;
  }

  if (!city) {
    formValid = false;
    formErrors["city"] = ERROR_MESSAGE.CITY_REQUIRED;
  }

  return { formValid, formErrors };
};
