import { emptySplitApi } from "../emptySplit-api";

export const productApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    productList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: "/products/list",
          method: "post",
          body: data,
        };
      },
    }),
    productGet: builder.query({
      query: (id) => ({ url: `/products/get/${id}`, method: "post" }),
    }),
  }),
  overrideExisting: false,
});

export const { useProductListQuery } = productApi;
