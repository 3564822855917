import { icons } from "./icons";
import { LABELS } from "./labels";
import { URLS } from "./urls";

export const STATUS_CODE = [400, 401, 403, 500];

export const DATE_FORMAT = {
  REGULAR: "D MMM YYYY, H:mm:ss",
  PROJECT_DATE: "D MMM YYYY | H:mm:ss",
  ALBUM_DATE: "MMM Do",
};

export const DATE_NEW_FORMAT = {
  REGULAR: "D MMM YYYY",
  DAY: "dddd",
  TIME: "H:mm:ss",
};

export const DATE_UPDATED_FORMAT = {
  REGULAR: "D MMM, YYYY",
};

export const DATE_STANDARD_FORMAT = {
  REGULAR: "D/MM/YYYY",
};

export const PAGINATION_DROP = [
  { _id: 10, name: 10 },
  { _id: 50, name: 50 },
  { _id: 100, name: 100 },
  { _id: 200, name: 200 },
];

export const SEARCH_DATA = {
  q: undefined,
  filter: {},
  limit: 10,
  skip: 0,
  sort: "desc",
  sortBy: "createdAt",
  // filterFlag: false,
  // searchFlag: false,
};

export const sidebarData: any = [
  {
    id: "1",
    title: LABELS.DASHBOARD_TEXT,
    path: URLS.DASHBOARD,
    icon: icons.ICON_DASHBOARD,
    permName: "store",
    type: "edit",
  },
  {
    id: "2",
    title: LABELS.STORE_MANAGEMENT_TEXT,
    path: URLS.STORE,
    icon: icons.ICON_STORE,
    permName: "store",
    type: "edit",
  },
  // {
  //   id: "3",
  //   title: "Brand Management",
  //   path: "/brand-management",
  // icon: icons.ICON_BRAND,
  // },
  {
    id: "4",
    title: LABELS.PRODUCT_MANAGEMENT_TEXT,
    path: URLS.PRODUCT,
    icon: icons.ICON_PRODUCT,
    permName: "product",
    type: "view",
  },
  {
    id: "5",
    title: LABELS.INVENTORY_TEXT,
    path: URLS.INVENTORY,
    icon: icons.ICON_INVENTORY,
    permName: "inventory",
    type: "view",
  },
  {
    id: "6",
    title: LABELS.VENDOR_MANAGEMENT_TEXT,
    path: URLS.VENDOR,
    icon: icons.ICON_INVENTORY,
    permName: "vendor",
    type: "view",
  },
  {
    id: "7",
    title: LABELS.ORDER_MANAGEMENT_TEXT,
    path: URLS.ORDER,
    icon: icons.ICON_ORDER,
    permName: "order",
    type: "view",
  },
  {
    id: "8",
    title: LABELS.CUSTOMER_MANAGEMENT_TEXT,
    path: URLS.CUSTOMER,
    icon: icons.ICON_CUSTOMERS,
    permName: "customer",
    type: "view",
  },

  {
    id: "9",
    title: LABELS.USER_MANAGEMENT_TEXT,
    icon: icons.ICON_USERS,
    visibleFor: [1],
    permName: "",
    type: "",
    subItems: [
      {
        id: "9-1",
        title: LABELS.USERS_TEXT,
        path: URLS.USER,
        visibleFor: [1],
        // permName: "",
        // type: "",
      },
      {
        id: "9-2",
        title: LABELS.ROLES_TEXT,
        path: URLS.ROLES,
        visibleFor: [1],
        // permName: "",
        // type: "",
      },
    ],
  },
  {
    id: "10",
    title: LABELS.STORE_COORDINATOR_TEXT,
    icon: icons.ICON_USERS,
    path: URLS.STORE_COORDINATOR,
    permName: "storeCoordinator",
    type: "view",
  },
  {
    id: "11",
    title: LABELS.REPORT_TEXT,
    path: URLS.REPORT,
    icon: icons.ICON_REPORTS,
    permName: "report",
    type: "view",
  },
];

export const sidebarPaths: any = [
  URLS.DASHBOARD,
  URLS.PRODUCT,
  URLS.STORE,
  URLS.INVENTORY,
  URLS.VENDOR,
  "/user-management",
  URLS.CUSTOMER,
  URLS.ORDER,
  "/order-details",
  URLS.STORE_COORDINATOR,
  URLS.USER_PROFILE,
];

export const PERMISSIONS = [
  // "product.list",
  // "product.create",
  // "product.update",
  // "product.delete",
  "store.edit",
  "store.view",
  "product.edit",
  "product.view",
  "inventory.edit",
  "inventory.view",
  "vendor.edit",
  "vendor.view",
  "order.edit",
  "order.view",
  "customer.edit",
  "customer.view",
  // "store.list",
  // "store.create",
  // "store.update",
  // "store.delete",

  // "vendor.list",
  // "vendor.create",
  // "vendor.update",
  // "vendor.delete",

  // "customer.list",
  // "customer.create",
  // "customer.update",
  // "customer.delete",

  // "order.list",
  // "order.create",
  // "order.update",
  // "order.delete",

  // "inventory.list",
  // "inventory.create",
  // "inventory.update",
  // "inventory.delete",

  // "report.list",
  // "report.create",
  // "report.update",
  // "report.delete",
  "storeCoordinator.edit",
  "storeCoordinator.view",
  "report.edit",
  "report.view",
];

const orderGetData = [
  {
    orderDetails: {
      itemCode: "#12312",
      designNo: "2345",
      itemSize: "M",
      itemColor: "Red",
      orderDate: "25/8/2023",
      destinationSite: "KORA",
      tradeGroup: "#215425",
      deliveryDate: "25/8/2023",
      lotNo: "40",
      Price: "56",
      subAssemblyItem: [
        {
          pant: [
            {
              size: "L",
              value: "31.5",
            },
            {
              size: "SH",
              value: "19.5",
            },
            {
              size: "SL",
              value: "26.5",
            },
            {
              size: "CH",
              value: "48",
            },
            {
              size: "ST",
              value: "47",
            },
            {
              size: "H",
              value: "47.5",
            },
            {
              size: "N",
              value: "16.5",
            },
            {
              size: "C.B",
              value: "18",
            },
            {
              size: "C.F",
              value: "17",
            },
          ],
          dupatta: [
            {
              size: "L",
              value: "31.5",
            },
            {
              size: "SH",
              value: "19.5",
            },
            {
              size: "SL",
              value: "26.5",
            },
            {
              size: "CH",
              value: "48",
            },
            {
              size: "ST",
              value: "47",
            },
            {
              size: "H",
              value: "47.5",
            },
            {
              size: "N",
              value: "16.5",
            },
            {
              size: "C.B",
              value: "18",
            },
            {
              size: "C.F",
              value: "17",
            },
          ],
          kurta: [
            {
              size: "L",
              value: "31.5",
            },
            {
              size: "SH",
              value: "19.5",
            },
            {
              size: "SL",
              value: "26.5",
            },
            {
              size: "CH",
              value: "48",
            },
            {
              size: "ST",
              value: "47",
            },
            {
              size: "H",
              value: "47.5",
            },
            {
              size: "N",
              value: "16.5",
            },
            {
              size: "C.B",
              value: "18",
            },
            {
              size: "C.F",
              value: "17",
            },
          ],
        },
      ],
    },
    customerInformation: {
      name: "",
      phone: "",
      email: "",
      address: "",
    },
    trackingInformation: {
      itemName: "",
      itemStatus: "",
      subAssemblyItem: [
        {
          pant: {
            status: "",
          },
          dupatta: {
            status: "",
          },
          kurta: {
            status: "",
          },
        },
      ],
    },
    isOrderConfirmed: true,
  },
];

const reportDetailData = {
  cutting: "pending",
  embrooidry: "pending",
  stitching: "pending",
  finishing: "pending",
  subassemblyItem: {
    pant: {
      cutting: "pending",
      embrooidry: "pending",
      stitching: "pending",
      finishing: "pending",
      jobOrderDate: "03-06-2024",
      validTillDate: "09-06-2024",
      jobReceiptDate: "24-04-2024",
      countOfDifferences: "-15",
      status: "Delayed Completed",
    },
    dupatta: {
      cutting: "pending",
      embrooidry: "pending",
      stitching: "pending",
      finishing: "pending",
      jobOrderDate: "03-06-2024",
      validTillDate: "09-06-2024",
      jobReceiptDate: "24-04-2024",
      countOfDifferences: "-15",
      status: "Delayed Completed",
    },
    kurta: {
      cutting: "pending",
      embrooidry: "pending",
      stitching: "pending",
      finishing: "pending",
      jobOrderDate: "03-06-2024",
      validTillDate: "09-06-2024",
      jobReceiptDate: "24-04-2024",
      countOfDifferences: "-15",
      status: "Delayed Completed",
    },
  },
};

const UserType = {
  SUPER: 1,
  BO: 2, // back office
  SC: 3, // store coordinator
};
